<template>
  <div class="login-type">
    <div @click="$emit('close')" class="login-type__close">
      <modal-cross fill="#333"/>
    </div>
    <div class="login-type__buttons">
      <base-button size="login-type" class="mr-5">Войти как партнёр</base-button>
      <base-button size="login-type">Войти как пользователь</base-button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseButton from "@/components/base/button/BaseButton.vue";
export default defineComponent({
  name: "LoginType"
})
</script>

<style scoped lang="scss">
.login-type{
  position: absolute;
  padding: 50px;
  background: #fff;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  transform: translate(-50%,0);
  top: 30%;
  left: 50%;
  &__close{
    position: absolute;
    right: 9px;
    top: 9px;
    width: 9px;
    height: 9px;
    cursor: pointer;
  }
  &__buttons{
    display: flex;
    align-items: center;
  }
}
</style>
