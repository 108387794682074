<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.7305 0H3.53293C1.58452 0 0 1.58452 0 3.53293V24.7305C0 26.6789 1.58452 28.2635 3.53293 28.2635H24.7305C26.6789 28.2635 28.2635 26.6789 28.2635 24.7305V3.53293C28.2635 1.58452 26.6789 0 24.7305 0Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9152 20.6399H15.1217C15.1217 20.6399 15.4862 20.596 15.6725 20.3762C15.8438 20.1744 15.8383 19.7956 15.8383 19.7956C15.8383 19.7956 15.8148 18.0218 16.566 17.7604C17.307 17.5031 18.2583 19.4747 19.2664 20.2332C20.0289 20.8068 20.6082 20.6813 20.6082 20.6813L23.3043 20.6399C23.3043 20.6399 24.7146 20.5445 24.0458 19.33C23.991 19.2305 23.6559 18.4314 22.0411 16.7889C20.3505 15.0699 20.5772 15.3479 22.6135 12.3744C23.8536 10.5635 24.3494 9.45821 24.1942 8.98484C24.0466 8.53374 23.1343 8.65278 23.1343 8.65278L20.0988 8.67345C19.9642 8.65595 19.8278 8.6823 19.7068 8.74919C19.5915 8.84153 19.4994 8.96429 19.439 9.10607C19.1246 9.99969 18.7498 10.8664 18.3178 11.699C16.9662 14.2135 16.4255 14.3467 16.2047 14.1904C15.6906 13.8262 15.8191 12.7286 15.8191 11.9485C15.8191 9.51132 16.1563 8.4953 15.1621 8.23228C14.8322 8.145 14.5891 8.0875 13.7451 8.07792C12.662 8.06637 11.7454 8.08161 11.2267 8.36033C10.8815 8.54506 10.6149 8.95828 10.7772 8.98218C10.9775 9.01128 11.4321 9.11634 11.6729 9.47542C11.9841 9.93909 11.9731 10.9803 11.9731 10.9803C11.9731 10.9803 12.1523 13.849 11.5556 14.2053C11.1465 14.4497 10.5852 13.9512 9.38008 11.6692C8.97109 10.8755 8.60922 10.0537 8.29673 9.20894C8.24112 9.06552 8.15536 8.93853 8.04646 8.83832C7.90533 8.73579 7.74672 8.66565 7.58018 8.63211L4.69545 8.65266C4.69545 8.65266 4.26257 8.66594 4.10356 8.87203C3.96204 9.05596 4.09228 9.43501 4.09228 9.43501C4.09228 9.43501 6.35046 15.2234 8.9079 18.1405C11.253 20.8152 13.9154 20.6397 13.9154 20.6397L13.9152 20.6399Z" fill="#333333"/>
  </svg>

</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "vkWhite"
})
</script>

<style scoped lang="scss">

</style>
