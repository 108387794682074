<template>
  <div class="login-modal">
    <div class="login-modal__close" @click="$emit('close')"><modal-cross/></div>
    <div class="login-modal__title">Вход или регистрация</div>
    <div class="login-modal__input">
      <base-input placeholder="Введите ваш e-mail" v-model="identity_value"/>
    </div>
    <div class="login-modal__button">
      <base-button size="auth" @click="SendLoginData" :disabled="disabled">Продолжить</base-button>
    </div>
    <div class="login-modal__policy">
      При входе, вы принимаете условия <router-link to="/policy">Пользовательского соглашения</router-link> и <router-link to="/policy">Политики обработки персональных данных</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "Login",
  emits: ["login","close"],
  data(): {
    identity_name: string;
    identity_value: string;
  }{
    return {
      identity_name: "phone",
      identity_value: ""
    }
  },
  computed: {
    disabled():boolean{
      return this.identity_value.length < 11
    }
  },
  methods: {
    SendLoginData(){
      this.$emit("login",this.identity_name,this.identity_value);
    }
  }
})
</script>

<style scoped lang="scss">
.login-modal{
  height: 243px;
  width: 308px;
  background: #FFF;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  position: fixed;
  transform: translate(-50%,0);
  top: 30%;
  left: 50%;
  padding: 30px;
  &__close{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  &__title{
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 100%;
    margin-bottom: 20px;
    @apply text-black;
  }
  &__input{
    margin-bottom: 10px;
  }
  &__button{
    margin-bottom: 10px;
  }
  &__policy{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 115%;
    color: #B4B4B4;
    a{
      @apply text-blue;
    }
  }
}
</style>
