<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="13"
    viewBox="0 0 9 13"
    fill="#3D7E9C"
  >
    <path
      d="M4.5 0C2.01872 0 0 2.02712 0 4.51874C0 7.65043 4.50443 13 4.50443 13C4.50443 13 9 7.49642 9 4.51874C9 2.02712 6.98136 0 4.5 0ZM5.85774 5.84183C5.48336 6.21769 4.99172 6.40566 4.5 6.40566C4.00836 6.40566 3.51656 6.21769 3.14234 5.84183C2.39365 5.09011 2.39365 3.86691 3.14234 3.11511C3.50486 2.75092 3.9871 2.55033 4.5 2.55033C5.0129 2.55033 5.49506 2.75099 5.85774 3.11511C6.60643 3.86691 6.60643 5.09011 5.85774 5.84183Z"

    />
    <path
      d="M7.07727 4.61458C7.07727 5.98688 5.96826 7.09935 4.60023 7.09935C3.23219 7.09935 2.12318 5.98688 2.12318 4.61458C2.12318 3.24228 3.23219 2.12981 4.60023 2.12981C5.96826 2.12981 7.07727 3.24228 7.07727 4.61458Z"

    />
  </svg>
</template>

<script>
export default {
  name: "placeholder",
};
</script>

<style scoped></style>
