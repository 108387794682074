<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7281 0H0.271928C0.0552825 0 -0.0825826 0.239659 0.0552825 0.439375L5.35324 7.96867C5.58958 8.28822 5.72745 8.70762 5.72745 9.12703V15.6977C5.74714 15.9174 5.9244 16.0572 6.10165 15.9773L8.82735 14.9295C9.01878 14.8559 9.27255 14.7844 9.27255 14.5793V9.12703C9.27255 8.70762 9.41042 8.30819 9.64676 7.96867L14.9447 0.439375C15.0826 0.239659 14.9447 0 14.7281 0Z" :fill="fill"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "filterIcon",
  props: {
    fill: {
      type: String,
      default: ():string =>{
        return "#C9C9C9";
      }
    }
  }
})
</script>

<style scoped lang="scss">

</style>
