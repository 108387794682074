import {fetch_sitemap} from "@/api/siteMap";

export default {
  LoadObjectsViewStyle({commit}) {
    const ObjectsView = localStorage.getItem("objects_view");
    if (ObjectsView) commit("SET_OBJECTS_VIEW_STYLE", ObjectsView);
  },
  GetSitemap({commit}) {
    commit("SET_SITEMAP_STATUS", {loading: true, error: false});
    fetch_sitemap()
      .then(({data}) => {
        commit("SET_SITEMAP_STATUS", {loading: false, error: false});
        commit("SET_SITEMAP", data);
      })
      .catch(() => {
        commit("SET_SITEMAP_STATUS", {loading: false, error: true});
      });
  },
};
