import {fetch_locations} from "@/api/siteMap.ts";

export default {
  FETCH_LOCALITIES: ({ commit, state }) => {
    fetch_locations(state.locality).then(({data})=>{
      commit("setLocalities", data);
    })

  },
};
