<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="icon-part" d="M20.119 0H2.87414C1.28905 0 0 1.28905 0 2.87414V20.119C0 21.704 1.28905 22.9931 2.87414 22.9931H20.119C21.704 22.9931 22.9931 21.704 22.9931 20.119V2.87414C22.9931 1.28905 21.704 0 20.119 0Z" fill="#333333"/>
    <path class="icon-part" fill-rule="evenodd" clip-rule="evenodd" d="M11.3199 16.791H12.3014C12.3014 16.791 12.5979 16.7552 12.7495 16.5765C12.8888 16.4123 12.8843 16.1041 12.8843 16.1041C12.8843 16.1041 12.8652 14.6611 13.4764 14.4485C14.0792 14.2391 14.8531 15.8431 15.6732 16.4601C16.2935 16.9267 16.7648 16.8246 16.7648 16.8246L18.9581 16.791C18.9581 16.791 20.1055 16.7133 19.5614 15.7253C19.5168 15.6444 19.2442 14.9943 17.9306 13.6581C16.5552 12.2597 16.7396 12.4858 18.3962 10.0668C19.405 8.59356 19.8084 7.69438 19.6821 7.30928C19.5621 6.9423 18.8199 7.03914 18.8199 7.03914L16.3504 7.05596C16.2409 7.04172 16.13 7.06315 16.0315 7.11757C15.9377 7.1927 15.8628 7.29256 15.8137 7.4079C15.5578 8.13489 15.253 8.83998 14.9015 9.51734C13.802 11.5629 13.3621 11.6713 13.1824 11.5441C12.7642 11.2479 12.8687 10.3549 12.8687 9.72031C12.8687 7.73759 13.1431 6.91102 12.3343 6.69706C12.0658 6.62605 11.8681 6.57927 11.1815 6.57147C10.3003 6.56208 9.5547 6.57448 9.13268 6.80122C8.85184 6.95151 8.63496 7.28768 8.76706 7.30712C8.92994 7.33079 9.29984 7.41626 9.49573 7.70838C9.74887 8.08559 9.73996 8.93264 9.73996 8.93264C9.73996 8.93264 9.88569 11.2664 9.40031 11.5562C9.06744 11.7551 8.61087 11.3496 7.63043 9.4931C7.29771 8.84737 7.00332 8.17882 6.7491 7.49159C6.70386 7.37492 6.63409 7.27161 6.5455 7.19008C6.43069 7.10668 6.30165 7.04961 6.16617 7.02233L3.81936 7.03905C3.81936 7.03905 3.4672 7.04985 3.33784 7.21751C3.22271 7.36714 3.32867 7.6755 3.32867 7.6755C3.32867 7.6755 5.16575 12.3845 7.2463 14.7577C9.15411 16.9336 11.32 16.7908 11.32 16.7908L11.3199 16.791Z" fill="white"/>
    <path id="vk-bg" d="M20.1292 0H2.87078C1.28341 0 0 1.28404 0 2.87218V20.1278C0 21.7047 1.28341 23 2.87078 23H20.1292C21.7166 23 23 21.7047 23 20.1278V2.87218C23 1.28404 21.7166 0 20.1292 0ZM18.9697 16.7938L16.7744 16.8276C16.7744 16.8276 16.3015 16.929 15.6823 16.4672C14.8605 15.8477 14.0837 14.237 13.4758 14.451C12.8678 14.665 12.8904 16.1068 12.8904 16.1068C12.8904 16.1068 12.8904 16.4109 12.7553 16.5798C12.5977 16.76 12.3049 16.7938 12.3049 16.7938H11.3255C11.3255 16.7938 9.15272 16.9403 7.25012 14.7551C5.1674 12.3898 3.33235 7.67042 3.33235 7.67042C3.33235 7.67042 3.21977 7.36631 3.33235 7.21988C3.46745 7.05093 3.81645 7.03967 3.81645 7.03967L6.16936 7.01714C6.30445 7.05093 6.42829 7.10725 6.54087 7.18609C6.63093 7.26494 6.70974 7.37757 6.75477 7.49021C7.00245 8.17728 7.29515 8.84182 7.63289 9.4951C8.61233 11.3536 9.07391 11.7591 9.40039 11.5563C9.88448 11.2635 9.73813 8.93193 9.73813 8.93193C9.73813 8.93193 9.74939 8.08717 9.50171 7.70421C9.29907 7.41136 8.92756 7.33252 8.76995 7.30999C8.63485 7.28746 8.84875 6.94956 9.1302 6.80313C9.558 6.57786 10.301 6.55534 11.1791 6.5666C11.8771 6.57786 12.0685 6.62292 12.3387 6.6905C13.1493 6.90451 12.8678 7.738 12.8678 9.72037C12.8678 10.3511 12.7665 11.2522 13.1831 11.5451C13.3632 11.669 13.8023 11.5676 14.9055 9.51763C15.2545 8.84182 15.5585 8.13222 15.8174 7.41136C15.8737 7.28746 15.9413 7.18609 16.0426 7.11851C16.1326 7.06219 16.2452 7.03967 16.3578 7.05093L18.8233 7.03967C18.8233 7.03967 19.5663 6.9383 19.6902 7.30999C19.814 7.69295 19.4087 8.59402 18.4068 10.0695C16.7406 12.4912 16.5604 12.2659 17.9339 13.6626C19.2511 14.9917 19.5213 15.645 19.5663 15.7238C20.118 16.715 18.9697 16.7938 18.9697 16.7938Z" fill="#3D87F5"/>
  </svg>
</template>

<script>
export default {
  name: "vk",

};
</script>

<style scoped></style>
