export default {
  SET_OBJECTS_VIEW_STYLE(state, payload) {
    localStorage.setItem("objects_view", payload);
    state.objects_view_style = payload;
  },
  SET_SITEMAP_STATUS(state, payload) {
    state.sitemapLoading = payload.loading;
    state.sitemapError = payload.error;
  },
  SET_SITEMAP(state, payload) {
    state.sitemap = payload.sort((a,b)=> a.sort - b.sort);
  },
  SHOW_AUTH(state){
    state.showAuth = true;
  },
  HIDE_AUTH(state) {
    state.showAuth = false;
  }
};
