import { offer_property_types_rules } from "./rules";
import TYPES from "../../../constants/FilterTypes";
import getters from "./getters";
export default {
  SET_ADDITIONAL_FILTER(state, payload) {
    state.additional_filter_data = payload;
  },
  SET_MAIN_FILTER(state, payload) {
    state.main_filter_data = payload;
    const types = [
      ...payload.objects_types_buy,
      ...payload.objects_types_rent,

      ...payload.commercial_types,
    ].reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    types.forEach((type) => {
      state.additional_filter = {
        ...state.additional_filter,
        [type.value]: {},
      };
    });
  },
  SET_OFFER_PROPERTY(state, payload) {
    state.main_filter = {
      offer_property: payload,
      text_search: state.main_filter.text_search,
      types: [],
      rooms_count: [],
      area: {
        from: "",
        to: "",
      },
      commerce_types: [],
      price: {
        from: "",
        to: "",
      },
      price_sqm: {
        from: "",

        to: "",
      },
    };
    this.commit("search/SET_MAIN_FILTER", state.main_filter_data);
  },
  SET_OFFER_PROPERTY_TYPE(state, payload) {
    if (state.main_filter.types.includes(payload)) {
      state.main_filter.types = state.main_filter.types.filter(
        (type) => type !== payload
      );
      state.additional_filter[payload] = {};
      state.additional_filter_tab = state.main_filter.types[0];
    } else {
      let offer_property = state.offer_property();
      let list = state.main_filter_data[`objects_types_${offer_property}`];
      let res = offer_property_types_rules(
        state.main_filter.types,
        payload,
        list
      );
      state.main_filter.types = [...res];
      state.additional_filter_tab = state.main_filter.types[0];
    }
    if (!getters.GET_TYPES_INCLUDES_NAME(state)([TYPES.house, TYPES.segment])) {
      state.main_filter.area = { from: "", to: "" };
    }
    if (
      !getters.GET_TYPES_INCLUDES_NAME(state)([TYPES.flat, TYPES.new_flats])
    ) {
      state.main_filter.rooms_count = [];
    }
    if (!getters.GET_TYPES_INCLUDES_NAME(state)([TYPES.commerce, TYPES.house])) {
      state.main_filter.area = { from: "", to: "" };
    }
    if (!getters.GET_TYPES_INCLUDES_NAME(state)([TYPES.commerce])) {
      state.main_filter.commerce_types = [];
    }
    if (state.main_filter.types.length === 1) {
      this.commit("search/SET_MAIN_FILTER", state.main_filter_data);
    }
  },
  SET_ROOMS_COUNT(state, payload) {
    if (state.main_filter.rooms_count.includes(payload)) {
      state.main_filter.rooms_count = state.main_filter.rooms_count.filter(
        (room) => room !== payload
      );
    } else {
      state.main_filter.rooms_count = [
        ...state.main_filter.rooms_count,
        payload,
      ];
    }
  },
  SET_COMMERCE_TYPE(state, payload) {
    console.log(payload);
    if (state.main_filter.commerce_types.includes(payload)) {
      state.main_filter.commerce_types = state.main_filter.commerce_types.filter(
        (room) => room !== payload
      );
      state.additional_filter[payload] = {};
      state.additional_filter_tab = state.main_filter.commerce_types[0] ?? 0;
    } else {
      state.main_filter.commerce_types = [
        ...state.main_filter.commerce_types,
        payload,
      ];
      state.additional_filter_tab = state.main_filter.commerce_types[0] ?? 0;
    }
  },
  SET_FILTER_LOADING_STATUS(state, { loading = false, error = false }) {
    state.filter_loading = loading;
    state.filter_error = error;
  },
  SET_SEARCH_TEXT(state, payload) {
    state.main_filter.text_search = payload;
  },
  SET_ADDITIONAL_FILTER_TAB(state, tab) {
    state.additional_filter_tab = tab;
  },
  SET_ADDITIONAL_FILTER_SELECT_DATA(state, payload) {
    const endpoint = state.additional_filter[payload.tab][payload.name];
    switch (payload.type) {
      case "multiselect": {
        if (!endpoint) {
          state.additional_filter[payload.tab][payload.name] = [payload.value];
        } else if (endpoint.includes(payload.value)) {
          state.additional_filter[payload.tab][payload.name] = endpoint.filter(
            (item) => item !== payload.value
          );
        } else {
          state.additional_filter[payload.tab][payload.name].push(
            payload.value
          );
        }
        break;
      }
      case "radio": {
        state.additional_filter[payload.tab][payload.name] = payload.value;
        break;
      }
      case "split_to": {
        if (!endpoint) {
          state.additional_filter[payload.tab][payload.name] = {};
        }
        state.additional_filter[payload.tab][payload.name].to = payload.value;
        break;
      }
      case "split_from": {
        const fields = state.additional_filter[payload.tab][payload.name];
        if (!endpoint) {
          state.additional_filter[payload.tab][payload.name] = {};
        }

        state.additional_filter[payload.tab][payload.name].from = payload.value;
        const from = fields.from.replace(/\s+/g, "");
        const to = fields.to.replace(/\s+/g, "");
        if (Number(from) > Number(to))
          state.additional_filter[payload.tab][payload.name].to = payload.value;
        break;
      }
      case "not_last": {
        state.additional_filter[payload.tab][payload.name].not_last =
          payload.value;
        break;
      }
      case "not_first": {
        state.additional_filter[payload.tab][payload.name].not_first =
          payload.value;
        break;
      }
    }
  },
  DELETE_FILTER_DATA_FROM_TAG(state, payload) {
    switch (payload.key) {
      case "text_search": {
        state[payload.inObj][payload.key] = "";
        break;
      }
      case "types": {
        state.main_filter.types = state.main_filter.types.filter(
          (type) => type !== payload.field_to_delete
        );
        state.additional_filter[payload.field_to_delete] = {};
        break;
      }
      case "price":
      case "area":
      case "price_sqm": {
        state[payload.inObj][payload.key].from = "";
        state[payload.inObj][payload.key].to = "";
        break;
      }
      case "commerce_types": {
        state.main_filter.commerce_types = state.main_filter.commerce_types.filter(
          (type) => type !== payload.field_to_delete
        );
        break;
      }
      case "rooms_count": {
        state[payload.inObj].rooms_count = [];
        break;
      }
      case "additional_filter": {
        delete state[payload.inObj][payload.field_to_delete_in][payload.field_to_delete]
        break;
      }
      default: {
        break;
      }
    }
  },
  OBJECTS_LOADING_START(state) {
    state.loading = true;
  },
  OBJECTS_LOADING_SUCCESS(state, payload) {
    state.loading = false;
    state.error = false;
    state.detectedObjects = payload;
  },
  OBJECTS_LOADING_FAIL(state,error) {
    state.errorCode = error;
    state.loading = false;
    state.error = true;
  },
  SAVE_SEARCH(state, payload) {
    if (payload.save)
      localStorage.setItem("saved_search", JSON.stringify(payload));
    state.old_search = payload.params;
  },
  SET_SORT(state, payload) {
    state.sort_by = payload.sort;
    state.sort_ord = payload.type;
  },
  SET_TYPE_BY_TYPE_NAME(state, payload) {
    if (!state.main_filter.types.includes(payload))
      state.main_filter.types.push(payload);
  },
  CHECK_MAIN_FILTER(state) {
    if (!state.main_filter.price.to) state.main_filter.price.to = "";
    if (!state.main_filter.price.from) state.main_filter.price.from = "";
    if (!state.main_filter.price_sqm.to) state.main_filter.price_sqm.to = "";
    if (!state.main_filter.price_sqm.from)
      state.main_filter.price_sqm.from = "";
    if (!state.main_filter.area.to) state.main_filter.area.to = "";
    if (!state.main_filter.area.from) state.main_filter.area.from = "";
  },
  RESET_FILTER(state) {
    state.main_filter = {
      text_search: "",
      offer_property: 1,
      types: [],
      rooms_count: [],
      area: {
        from: "",
        to: "",
      },
      commerce_types: [],
      lot_area: {
        from: "",
        to: "",
      },
      price: {
        from: "",
        to: "",
      },
      price_sqm: {
        from: "",
        to: "",
      },
    };
    this.commit("search/SET_MAIN_FILTER", state.main_filter_data);
  },
};
