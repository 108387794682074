<template>
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 1.77981L13.1332 -8.16013e-08L7.48028 5.42777L1.85363 0.0252052L-7.94533e-08 1.81768L7.48028 9L15 1.77981Z"
      :fill="fill ?? '#333333'"
    />
  </svg>
</template>

<script>
export default {
  name: "chevronDown",
  props: ["fill"]
};
</script>

<style scoped></style>
