<template>
  <div class="h-full md:h-auto relative" v-click-outside="closeDropdown">
    <label class="block text-sm leading-5 font-medium text-gray-700">
      {{ label }}
    </label>
    <div
      class=""
    >
      <div class="relative h-full">
        <span class="inline-block w-full h-full">
          <button
            type="button"
            @click="toggleDropdown"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            class="
              cursor-pointer
              relative
              w-full
              h-full
              pl-3
              pr-16
              py-2
              text-left
              focus:outline-none
              focus:shadow-outline-blue
              focus:border-blue-300
              transition
              ease-in-out
              duration-150
              sm:leading-5
            "
          >
            <span class="flex items-center space-x-3">
              <span class="block truncate first-letter-upper">
                <slot name="value">
                  <span v-if="value.length !== 0">
                    <span v-if="value.length === 1">
                      <span v-for="d in data" :key="d">
                        <span v-if="value.includes(d.value)">
                          {{ d.name + " " }}
                        </span>
                      </span>
                    </span>
                    <span v-if="value.length > 1">
                      <span v-for="d in data" :key="d">
                        <span v-if="value.includes(d.value)">
                          {{ d.name + " " }}
                        </span>
                      </span>
                    </span>
                    <span v-if="value.length > 3">
                      {{ " ..." }}
                    </span>
                  </span>
                  <span v-else> Любой </span>
                </slot>
              </span>
            </span>
            <span
              class="
                absolute
                inset-y-0
                arrow
                right-0
                rounded
                flex
                items-center
                pr-7
                pointer-events-none
              "
              :class="{ rotate180deg: isOpen }"
            >
              <font-awesome-icon icon="chevron-down"/>
            </span>
          </button>
        </span>
      </div>
    </div>
    <div
      v-show="isOpen"

    >
      <ul class="list">
        <li
          @click="select(d.value, d.name)"
          v-for="d in data"
          v-bind:key="d"
          class="list__item"
          :class="{active: value.includes(d.value)}"
        >
          <div class="list__item__check" :class="{checked: value.includes(d.value)}">
            <checked v-if="value.includes(d.value)"/>
          </div>
          <div class="list__item__name first-letter-upper">{{ d.name }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ClickOutside from "@/helpers/clickoutside";

interface Select {
  name: string;
  value: string | number;
}

export default defineComponent({
  name: "BaseSelect",
  props: {
    data: {
      type: [Object] as PropType<Select[]>,
      required: true
    },
    label: {
      type: String,
      default: (): string => {
        return "Выбор";
      }
    },
    modelValue: {
      type: String || Number,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    isSelected(value: string | number): boolean {
      return this.modelValue === value;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    openDropdown() {
      this.isOpen = true;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    select(value: string | number) {
      this.isOpen = false;
      this.$emit("valueSelect", value);
    },
  },
  directives: {
    ClickOutside,
  },
});
</script>

<style scoped>
.rotate180deg svg {
  transition: all ease-in-out 0.3s;
  transform: rotate(180deg);
}
</style>
