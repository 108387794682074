import { render } from "./CookieNotice.vue?vue&type=template&id=7e79a5f0&scoped=true"
import script from "./CookieNotice.vue?vue&type=script&lang=js"
export * from "./CookieNotice.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./CookieNotice.vue?vue&type=style&index=0&id=7e79a5f0&module=true&scoped=true&lang=scss"
cssModules["$style"] = style0
script.render = render
script.__scopeId = "data-v-7e79a5f0"

export default script