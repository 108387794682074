import { createRouter, createWebHistory } from "vue-router";
//middlewares
import Auth from "@/middlewares/Auth";

const Search = () =>
  import(/* webpackChunkName: "view-[request]" */ "@/views/Search/Search.vue");
const ResidentalComplex = () =>
  import(
    /* webpackChunkName: "view-[request]" */ "@/views/ResidentalComplex/ResidentalComplex.vue"
  );
const Home = () =>
  import(/* webpackChunkName: "view-[request]" */ "@/views/Home/Home.vue");
const Contacts = () =>
  import(
    /* webpackChunkName: "view-[request]" */ "@/views/Contacts/Contacts.vue"
  );
const News = () =>
  import(/* webpackChunkName: "view-[request]" */ "@/views/News/News.vue");
const NewsItem = () =>
  import(
    /* webpackChunkName: "view-[request]" */ "@/views/NewsItem/NewsItem.vue"
  );
const Offer = () =>
  import(/* webpackChunkName: "view-[request]" */ "@/views/Offer/Offer.vue");
const Policy = () =>
  import(/* webpackChunkName: "view-[request]" */ "@/views/Policy.vue");
const AboutCookiesPage = () =>
  import(
    /* webpackChunkName: "view-[request]" */ "@/views/AboutCookiesPage.vue"
  );
const Profile = () =>
  import(
    /* webpackChunkName: "view-[request]" */ "@/views/Profile/Profile.vue"
  );
const NotFound = () =>
  import(/* webpackChunkName: "view-[request]" */ "@/views/Errors/404.vue");
const ForDevelopers = () =>
  import(
    /* webpackChunkName: "view-[request]" */ "@/views/ForDevelopers/ForDevelopers.vue"
  );
const NoAccess = () =>
  import(/* webpackChunkName: "view-[request]" */ "@/views/Errors/403.vue");
const TEST = () =>
  import(/* webpackChunkName: "view-[request]" */ "@/views/test.vue");
const SITENAME = "Loft";

const routes = [
  {
    path: "/testing",
    component: TEST
  },
  //main
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: SITENAME,
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: {
      title: `${SITENAME} | Контакты`,
    },
  },
  {
    path: "/offers",
    name: "Offers",
    component: Search,
    meta: {
      title: `${SITENAME} | Вся недвижимость`,
    },
  },
  {
    path: "/offers/new_flats",
    name: "NewFlats",
    component: Search,
    meta: {
      type: "1",
      title: `${SITENAME} | Новостройки`,
    },
  },
  {
    path: "/offer/:id",
    name: "Offer",
    props: true,
    component: Offer,
  },
  {
    path: "/realty_objects/residential_complex/:id",
    name: "ResidentialComplex",
    component: ResidentalComplex,
  },
  {
    path: "/company/:id/profile",
    name: "Company",
    component: ForDevelopers,
  },
  //will be later
  {
    path: "/news",
    name: "News",
    component: News,
    meta: {
      title: `${SITENAME} | Новости`,
    },
  },
  {
    path: "/news/:id",
    name: "NewItem",
    component: NewsItem,
  },
  //profile
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      middlewares: [Auth],
    },
  },
  //errors
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/404",
    component: NotFound,
    meta: {
      title: "Not Found",
    },
  },
  {
    path: "/403",
    component: NoAccess,
    name: "NoAccess",
    meta: {
      title: "No access",
    },
  },
  //others
  {
    path: "/about_cookies",
    name: "about_cookies",
    component: AboutCookiesPage,
    meta: {
      title: `${SITENAME} | About cookies`,
    },
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
    meta: {
      title: `${SITENAME} | Policy`,
    },
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0, behavior: "smooth" };
    }
  },
});

//следующий защитник роута
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) {
    return context.next;
  }
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, nextMiddleware });
  };
}

//перед переходом на след роут
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  document.title = to.meta.title ?? "Loft";
  if (to.name === from.name) {
    return next();
  }
  if (to.meta.middlewares) {
    const middlewares = Array.isArray(to.meta.middlewares)
      ? to.meta.middlewares
      : [to.meta.middlewares];
    const context = { from, next, router, to };
    const nextMiddleware = nextFactory(context, middlewares, 1);
    return middlewares[0]({ ...context, next: nextMiddleware });
  }
  else {
    return next();
  }
});

export default router;
