<template>
  <svg width="62" height="28" viewBox="0 0 62 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.06667 21.3533H15.2333V27H0.2V1.33333H6.06667V21.3533ZM33.1482 24.7267C31.2659 26.5844 28.9682 27.5133 26.2548 27.5133C23.5415 27.5133 21.2437 26.5844 19.3615 24.7267C17.4793 22.8444 16.5382 20.5467 16.5382 17.8333C16.5382 15.12 17.4793 12.8344 19.3615 10.9767C21.2437 9.09444 23.5415 8.15333 26.2548 8.15333C28.9682 8.15333 31.2659 9.09444 33.1482 10.9767C35.0304 12.8344 35.9715 15.12 35.9715 17.8333C35.9715 20.5467 35.0304 22.8444 33.1482 24.7267ZM23.2482 20.95C24.0548 21.7567 25.057 22.16 26.2548 22.16C27.4526 22.16 28.4548 21.7567 29.2615 20.95C30.0682 20.1433 30.4715 19.1044 30.4715 17.8333C30.4715 16.5622 30.0682 15.5233 29.2615 14.7167C28.4548 13.91 27.4526 13.5067 26.2548 13.5067C25.057 13.5067 24.0548 13.91 23.2482 14.7167C22.4415 15.5233 22.0382 16.5622 22.0382 17.8333C22.0382 19.1044 22.4415 20.1433 23.2482 20.95ZM48.6286 6.1C46.3553 5.90444 45.2186 6.76 45.2186 8.66667H48.6286V13.9467H45.2186V27H39.7186V13.9467H37.262V8.66667H39.7186C39.7186 6.02666 40.4642 4.02222 41.9553 2.65333C43.4464 1.26 45.6709 0.648887 48.6286 0.819997V6.1ZM61.9286 13.9467H58.152V20.4367C58.152 21.17 58.4453 21.6344 59.032 21.83C59.6186 22.0256 60.5842 22.0867 61.9286 22.0133V27C58.4331 27.3667 56.0131 27.0367 54.6686 26.01C53.3242 24.9589 52.652 23.1011 52.652 20.4367V13.9467H49.7186V8.66667H52.652V5.18333L58.152 3.53333V8.66667H61.9286V13.9467Z" fill="#3D7E9C"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "logo"
})
</script>

<style scoped lang="scss">

</style>
