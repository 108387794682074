<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
  >
    <path
      d="M5 1.57143V4.71429C5 4.87214 4.88812 5 4.75 5H3.25C3.11187 5 3 4.87214 3 4.71429V1.57143C3 1.25643 3.22438 1 3.5 1H4.5C4.77562 1 5 1.25643 5 1.57143Z"
      fill="#333333"
    />
    <path
      d="M15 1.57143V4.71429C15 4.87214 14.8881 5 14.75 5H13.25C13.1119 5 13 4.87214 13 4.71429V1.57143C13 1.25643 13.2244 1 13.5 1H14.5C14.7756 1 15 1.25643 15 1.57143Z"
      fill="#333333"
    />
    <path
      d="M18 5.95877V12.0825C18 12.85 17.3693 13.4742 16.5938 13.4742H1.40625C0.630703 13.4742 0 12.85 0 12.0825V5.95877C0 5.19122 0.630703 4.56702 1.40625 4.56702H16.5938C17.3693 4.56702 18 5.19122 18 5.95877Z"
      fill="#333333"
    />
    <path
      d="M13.5625 11.1546V16.1649C13.5625 16.6253 13.1839 17 12.7188 17H4.84375C4.37863 17 4 16.6253 4 16.1649V11.1546C4 11.0008 4.12586 10.8763 4.28125 10.8763H13.2812C13.4366 10.8763 13.5625 11.0008 13.5625 11.1546Z"
      fill="#333333"
    />
    <g filter="url(#filter0_d)">
      <path
        d="M16.0312 7.35052C16.4972 7.35052 16.875 6.97666 16.875 6.51547C16.875 6.05429 16.4972 5.68042 16.0312 5.68042C15.5653 5.68042 15.1875 6.05429 15.1875 6.51547C15.1875 6.97666 15.5653 7.35052 16.0312 7.35052Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter1_d)">
      <path
        d="M13.7812 7.35052C14.2472 7.35052 14.625 6.97666 14.625 6.51547C14.625 6.05429 14.2472 5.68042 13.7812 5.68042C13.3153 5.68042 12.9375 6.05429 12.9375 6.51547C12.9375 6.97666 13.3153 7.35052 13.7812 7.35052Z"
        fill="white"
      />
    </g>
    <path
      d="M3 2C3 0.895431 3.89543 0 5 0H13C14.1046 0 15 0.895431 15 2H3Z"
      fill="#333333"
    />
    <path
      d="M12.0938 13.4742H6.1875C6.03218 13.4742 5.90625 13.5989 5.90625 13.7526C5.90625 13.9063 6.03218 14.0309 6.1875 14.0309H12.0938C12.2491 14.0309 12.375 13.9063 12.375 13.7526C12.375 13.5989 12.2491 13.4742 12.0938 13.4742Z"
      fill="white"
    />
    <path
      d="M12.0938 14.866H6.1875C6.03218 14.866 5.90625 14.9906 5.90625 15.1443C5.90625 15.298 6.03218 15.4227 6.1875 15.4227H12.0938C12.2491 15.4227 12.375 15.298 12.375 15.1443C12.375 14.9906 12.2491 14.866 12.0938 14.866Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d"
        x="11.1875"
        y="3.68042"
        width="9.6875"
        height="9.6701"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="8.9375"
        y="3.68042"
        width="9.6875"
        height="9.6701"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "print",
};
</script>

<style scoped></style>
