<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M20.4395 0H3.18945C1.60389 0 0.314453 1.28944 0.314453 2.875V20.125C0.314453 21.7106 1.60389 23 3.18945 23H20.4395C22.025 23 23.3145 21.7106 23.3145 20.125V2.875C23.3145 1.28944 22.025 0 20.4395 0Z"
        fill="#333333"
      />
      <path
        d="M19.7207 11.5H16.127V8.625C16.127 7.8315 16.771 7.90625 17.5645 7.90625H19.002V4.3125H16.127C13.745 4.3125 11.8145 6.24306 11.8145 8.625V11.5H8.93945V15.0938H11.8145V23H16.127V15.0938H18.2832L19.7207 11.5Z"
        fill="#DADADA"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="23"
          height="23"
          fill="white"
          transform="translate(0.314453)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "facebook",
};
</script>

<style scoped></style>
