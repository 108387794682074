<template>
  <div class="base-dropdown" :class="{active: isOpen}">
    <div v-click-outside="closeDropdown">
      <div class="base-dropdown__button" @click="toggleDropdown">
        <slot name="values"/>
        <div class="base-dropdown__button__icon">
          <font-awesome-icon icon="chevron-down"/>
        </div>
      </div>
      <div v-if="isOpen" class="base-dropdown__drop styled-scroll" :class="{'align-right': align_right}">
          <slot name="inputs"/>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "@/helpers/clickoutside";

export default {
  name: "PriceComponent",
  components: {},
  props: {
    data: Array,
    value: String,
    align_right: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    padding: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    isSelected(value) {
      return this.value === value;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    openDropdown() {
      this.isOpen = true;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    select(value) {
      this.isOpen = false;
      this.$emit("valueSelect", value);
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
.base-dropdown {
  position: relative;
  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    border-right: 1px solid #B4B4B4;
    font-size: 15px;
      &__icon{
        transition: .5s;
        margin-left: 30px;
      }
  }
  &.active{
    .base-dropdown__button__icon{
      transform: rotate(180deg);
    }
  }
  &__drop{
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    background: #fff;
    overflow-y: scroll;
    border-radius: 4px;
    z-index: 10;
    &.align-right{
      left: unset;
      right: 0;
    }
  }
  @media(min-width: 1280px) and (max-width: 1535px) {
    &__button{
      font-size: 14px;
      padding: 10px 15px;
      &__icon{
        margin-left: 15px;
      }
    }
  }
  @media(min-width: 1025px) and (max-width: 1279px) {
    &__button{
      font-size: 12px;
      padding: 10px;
      &__icon{
        margin-left: 15px;
      }
    }
  }
  @media(max-width: 1024px){
    border-bottom: 1px solid #D3D3D3;
    &__button {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px 30px 10px 20px;
      border-right: none;
      &__icon{
        margin-left: auto;
      }
    }
    &__drop{
      position: unset;
      width: 100%;
      top: 100%;
    }
  }
}

.styled-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #333;

  }
}
</style>
