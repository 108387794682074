<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7.43179" y="6.04886" width="19" height="2.11111" transform="rotate(44.2543 7.43179 6.04886)" />
    <rect x="20.7542" y="7.2381" width="19" height="2.11111" transform="rotate(134.254 20.7542 7.2381)" />
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "cross"
})
</script>

<style scoped lang="scss">

</style>
