import {createApp} from "vue";
import App from "./App.vue";
import Vue3TouchEvents from "vue3-touch-events";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import {registerBaseComponents, loadPlugins} from "@/helpers";
import {registerIcons} from "@/helpers/registerIcons.js";
import {VueReCaptcha} from 'vue-recaptcha-v3'
//import '@/styles/base.css'
import "./styles/tailwind.css";
import Maska from "maska";
// Export the app, so it can be accessed by plugins
export const app = createApp(App);

/**
 * Specify plugins to load from the /plugins directory
 * Pass file names without any extension like so
 *
 * @example
 *
 * loadPlugins(['vue-fontawesome'])
 */
loadPlugins(["vue-fontawesome"]);

/**
 * Automatically imports and registers base components
 * Make sure you have at least one Base components in components/base directory
 */
registerBaseComponents(app);
registerIcons(app);

app.use(store)
  .use(router)
  .use(Vue3TouchEvents)
  .use(Maska)
  .use(VueReCaptcha, {siteKey: '6LcndXcbAAAAAG0ytf1Vp-qvJsQxcAmyujB9tCk4'})
  .mount("#app");

