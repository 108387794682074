<template>
  <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.02243 17.0012L10 15.0096L3.96914 8.97872L9.97199 2.97587L7.98036 0.998302L7.01349e-07 8.97872L8.02243 17.0012Z" fill="#333333"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "chevronLeft"
})
</script>

<style scoped lang="scss">

</style>
