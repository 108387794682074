
import {defineComponent} from 'vue';
import AuthorizationDropdown from "@/components/common/Auth/AuthorizationDropdown.vue";
export default defineComponent({
  name: "HeaderMobile",
  components: {
    AuthorizationDropdown
  },
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    sitemap() {
      return this.$store.state.site.sitemap;
    }
  },
  methods: {
    ToggleCallback() {
      let targets = document.querySelectorAll<HTMLElement>("#itlcb-step2, #itlcb-bg-fill");
      targets.forEach(target => {
        target.style.visibility = "visible";
      })
    },
    ShowMenu(): void {
      this.showMenu = true;
    },
    HideMenu(): void {
      this.showMenu = false
    }
  }
})
