<template>
  <div class="authorization-dropdown" v-click-outside="HideList">
    <div class="authorization-dropdown__button" @click="ShowList">
      <font-awesome-icon :icon="icon" class="mr-2"/>
      <span>{{ isAuth ? "Профиль" : "Войти" }}</span>
    </div>
    <div v-if="show && !isAuth" class="authorization-dropdown__list">
      <div class="authorization-dropdown__list__item" @click="ShowAuthModal">
        Войти на сайт
      </div>
      <a href="https://crm.loft.ug/" target="_blank" class="authorization-dropdown__list__item">
        Войти в loft.CRM
      </a>
    </div>
    <div v-if="show && isAuth" class="authorization-dropdown__list">
      <div class="authorization-dropdown__list__item" @click="To('Profile')">
        Настройки
      </div>
      <div class="authorization-dropdown__list__item" @click="Logout">
        <font-awesome-icon class="mr-2" icon="sign-out-alt"/>
        Выйти
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ClickOutside from "@/helpers/clickoutside.js";
export default defineComponent({
  name: "AuthorizationDropdown",
  data() {
    return {
      show: false
    }
  },
  computed: {
    isAuth(): boolean {
      return this.$store.state.user.isAuth;
    },
    icon(): string {
      return this.isAuth ? "user" : "sign-in-alt"
    }
  },
  methods: {
    ShowList(): void {
      this.show = true;
    },
    HideList(): void {
      this.show = false
    },
    ShowAuthModal(): void {
      this.HideList()
      this.$store.commit('site/SHOW_AUTH');
    },
    Logout(): void {
      this.HideList()
      this.$store.commit('user/Logout');
    },
    To(routeName: string): void {
      this.HideList()
      this.$router.push({name: routeName})
    }
  },
  directives: {
    ClickOutside
  }
})
</script>

<style lang="scss" scoped>
.authorization-dropdown {
  position: relative;

  &__button {
    cursor: pointer;
    @apply transition-colors ease-in-out lg:block duration-300 hover:text-blue;

  }

  &__list {
    position: absolute;
    right: -2px;
    top: calc(100% + 12px);
    width: max-content;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
    @apply bg-milk;
    &__item {
      display: block;
      padding: 5px 10px;
      cursor: pointer;

      &:hover {
        @apply bg-white;
      }
    }
  }
  @media(max-width: 768px) {
    &__button {
      color: #C9C9C9;
      span{
        display: none;
      }
    }
  }
}
</style>
