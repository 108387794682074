<template>
  <svg
    width="47"
    height="14"
    viewBox="0 0 47 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.21031 -3.47853e-06L9.96116 2.66144L7.44634 5.09461L47 5.09462L47 8.85851L7.44634 8.85851L9.96116 11.2917L7.21031 13.9531L-6.09911e-07 6.97656L7.21031 -3.47853e-06Z"
      fill=""
    />
  </svg>
</template>

<script>
export default {
  name: "arrow",
};
</script>

<style scoped></style>
