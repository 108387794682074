import axios from "axios";
import store from "@/store";

// Стандратная конфигурация сущности запроса
const axiosParams = {
  // Меняем baseURL в зависимости от env
  baseURL: process.env.VUE_APP_API_URL,
};

// Создаем сущность axios использую конфигурацию
const axiosInstance = axios.create(axiosParams);

const didAbort = (error) => axios.isCancel(error);

const getCancelSource = () => axios.CancelToken.source();

const axiosAuthInstance = axios.create(axiosParams);

/**
 * Перехватываем при запросе
 *
 * Получаем токен авторизации, указываем его в конфигурации и возвращаем конфиг
 */
axiosAuthInstance.interceptors.request.use((config) => {
  const access_token = store.getters["auth/getAccessToken"];
  config.headers.common["Authorization"] = `Bearer ${access_token}`;
  return config;
});

/**
 * Перехватываем при ответе
 *
 */
axiosAuthInstance.interceptors.response.use(
  // Если все хорошо
  (response) => {
    return response;
  },

  // Если плохо
  async (error) => {
    // Если токен авторизации просрочек, и получаем 403
    if (error.response.status === 403) {
      // Конфигурируем payload
      const payload = {
        refresh: store.getters["auth/getRefreshToken"],
      };
      const response = await axios.post(
        process.env.NODE_ENV === "development"
          ? "http://loft.test/api/auth/login/refresh/"
          : "/api/auth/login/refresh/",
        payload
      );
      //Записываем новые
      await store.commit("auth/setRefreshToken", response.data.refresh);
      await store.commit("auth/setAccessToken", response.data.access);
      await store.commit("auth/setAuthStatus", true);
      //Указываем в конфигурации новый токен
      error.config.headers["Authorization"] = `Bearer ${response.data.access}`;
      //Возвращаем запрос с новым конфигом
      return axios(error.config);
    }
    else {
      return Promise.reject(error);
    }
  }
);

const withAbort =
  (fn) =>
  async (...args) => {
    const originalConfig = args[args.length - 1];
    // Получаем abort свойство из конфига
    let { abort, ...config } = originalConfig;

    // Создаем cancel токен и метод abort если abort функция была передана
    if (typeof abort === "function") {
      const { cancel, token } = getCancelSource();
      config.cancelToken = token;
      abort(cancel);
    }

    try {
      // Передаем все аргументы из args, кроме конфигурации
      return await fn(...args.slice(0, args.length - 1), config);
    } catch (error) {
      // Добавляем свойство "aborted" к ошибке, если запрос был отменен
      didAbort(error) && (error.aborted = true);
      throw error;
    }
  };

// Main api function
const api = (axios) => {
  return {
    get: (url, config = {}) => withAbort(axios.get)(url, config),
    post: (url, body, config = {}) => withAbort(axios.post)(url, body, config),
    put: (url, body, config = {}) => withAbort(axios.put)(url, body, config),
    patch: (url, body, config = {}) =>
      withAbort(axios.patch)(url, body, config),
    delete: (url, config = {}) => withAbort(axios.delete)(url, config),
    // TODO: DRY
    get_with_auth: (url, config = {}) => {
      withAbort(axiosAuthInstance.get)(url, config);
    },
    post_with_auth: (url, body, config = {}) => {
      withAbort(axiosAuthInstance.post)(url, body, config);
    },
    put_with_auth: (url, body, config = {}) => {
      withAbort(axiosAuthInstance.put)(url, body, config);
    },
  };
};

export default api(axiosInstance);
