<template>
  <div class="password-input">
    <input :placeholder="placeholder" :type="type" :value="modelValue" @input="Update">
    <div class="password-input__icon" @click="ToggleHide">
      <font-awesome-icon :icon="icon"/>
    </div>
    <div class="password-input__error" v-if="error">{{ error }}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "PasswordInput",
  props: {
    placeholder: {
      type: String,
      default: (): string => {
        return "";
      }
    },
    modelValue: {
      type: String,
      default: (): string => {
        return "";
      }
    },
    error: {
      type: String,
      default: (): string => {
        return "";
      }
    }
  },
  data() {
    return {
      hide: true
    }
  },
  computed: {
    type(): string {
      return this.hide ? "password" : "text";
    },
    icon(): string {
      return this.hide ? "eye-slash" : "eye";
    }
  },
  methods: {
    Update(e: InputEvent): void {
      const value = (e.target as HTMLInputElement).value;
      this.$emit('update:modelValue', value);
    },
    ToggleHide(): void {
      this.hide = !this.hide;
    }
  }
})
</script>

<style lang="scss" scoped>
.password-input {
  position: relative;

  input {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100.9%;
    padding: 10px 8px;
    border: 1px solid #B4B4B4;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    @apply text-black;
    &:focus {
      outline: none;
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    @apply text-black;
    &:hover {
      @apply text-gray;
    }
  }
  &__error{
    color: red;
    font-size: 12px;
  }
}
</style>
