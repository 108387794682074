
import {defineComponent} from 'vue';


export default defineComponent({
  name: "footerMobile",
  computed: {
    sitemap() {
      return this.$store.state.site.sitemap;
    }
  },
  methods: {
    ToggleCallback() {
      let targets = document.querySelectorAll<HTMLElement>("#itlcb-step2, #itlcb-bg-fill");
      targets.forEach((target) => {
        target.style.visibility = "visible";
      });
    },
  }
})
