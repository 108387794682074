<template>
  <div class="code-input">
    <div class="code-input__input" v-for="input in SymbolsCount" :key="input" :class="{ active: code[input] !== '' }">
      <input
        :id="`code-input-${input}`"
        type="text"
        :value="code[input]"
        @input="OnSetSymbol"
        @keydown.delete="OnDeleteSymbol"
        @keydown.right="FocusElement(focus + 1)"
        @keydown.left="FocusElement(focus - 1)"
        @click="SetFocus(input)"
        @paste="OnPaste(input)"
      />
    </div>
    <div class="code-input__error" v-if="error">{{ error }}</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "CodeInput",
  emits: ["update:modelValue"],
  props: {
    SymbolsCount: {
      type: Number,
      default: (): number => {
        return 6;
      },
    },
    modelValue: {
      type: String,
      default: (): string => {
        return "";
      },
    },
    error: {
      type: String,
      default: ():string =>{
        return "";
      }
    }
  },
  data(): {
    code: { [key:string]: string };
    focus: number;
  } {
    return {
      code: {},
      focus: 0,
    };
  },
  mounted() {
    this.SetDefault();
  },
  watch: {
    codeString() {
      if (this.codeString.length === 6) {
        this.$emit("update:modelValue", this.codeString);
      } else {
        this.$emit("update:modelValue", "");
      }
    },
  },
  computed: {
    codeString(): string {
      let res = "";
      for (let key in this.code) {
        res += String(this.code[key]);
      }
      return res;
    },
  },
  methods: {
    SetDefault(): void {
      for (let x = 1; x <= this.SymbolsCount; x++) {
        this.code[x] = "";
      }
    },
    OnSetSymbol(event: InputEvent): void {
      const data = event.data !== null ? event.data : "";
      this.code[this.focus] = data;
      if (data) {
        this.focus++;
        this.FocusElement(this.focus);
      }
    },
    OnDeleteSymbol(): void {
      this.code[this.focus] = "";
      this.focus--;
      this.FocusElement(this.focus);
    },
    SetFocus(num: number): void {
      this.focus = num;
    },
    FocusElement(elemId: number): void {
      this.SetFocus(elemId);
      const inputToFocus = document.querySelector<HTMLElement>(`#code-input-${elemId}`);
      inputToFocus?.focus();
    },
    OnPaste(focusedInputIndex: number): void {
      const focusedInput = document.querySelector<HTMLInputElement>(`#code-input-${focusedInputIndex}`);
      setTimeout(() => {
        const symbols: string[] = focusedInput?.value.trim().split("") ?? [];
        this.SetDefault();
        for (let key in this.code) {
          this.code[key] = symbols[Number(key) - 1];
        }
      }, 200);
    },
  },
});
</script>

<style scoped lang="scss">
.code-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  &__input {
    width: 36px;
    height: 36px;
    border-radius: 5px;
    background: #B4B4B4;
    padding: 1px;
    input {
      padding: 13px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 100.9%;
      border: none;
      width: 100%;
      height: 100%;
      color: #333;
      border-radius: 5px;
    }
    &.active {
      background: #333;
    }
  }
  &__error{
    width: 100%;
    color: red;
    font-size: 12px;
  }

}
</style>
