<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect id="inst-circle" width="23" height="23" rx="4" fill="white"/>
    <path id="inst-bg" d="M19.6309 0H3.36914C1.50938 0 0 1.50938 0 3.36914V19.6309C0 21.4906 1.50938 23 3.36914 23H19.6309C21.4906 23 23 21.4906 23 19.6309V3.36914C23 1.50938 21.4906 0 19.6309 0ZM11.5 17.6094C8.15786 17.6094 5.43555 14.8871 5.43555 11.5449C5.43555 8.20269 8.15786 5.48047 11.5 5.48047C14.8421 5.48047 17.5645 8.20269 17.5645 11.5449C17.5645 14.8871 14.8421 17.6094 11.5 17.6094ZM18.9121 5.48047C18.1709 5.48047 17.5645 4.87402 17.5645 4.13281C17.5645 3.3916 18.1709 2.78516 18.9121 2.78516C19.6533 2.78516 20.2598 3.3916 20.2598 4.13281C20.2598 4.87402 19.6533 5.48047 18.9121 5.48047Z" fill="url(#paint0_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="1.54426" y1="21.5016" x2="22.8553" y2="3.05345" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFDD55"/>
        <stop offset="0.5" stop-color="#FF543E"/>
        <stop offset="1" stop-color="#C837AB"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "instagram",
};
</script>

<style scoped></style>
