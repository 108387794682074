<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.119 0H2.87414C1.28905 0 0 1.28905 0 2.87414V20.119C0 21.704 1.28905 22.9931 2.87414 22.9931H20.119C21.704 22.9931 22.9931 21.704 22.9931 20.119V2.87414C22.9931 1.28905 21.704 0 20.119 0Z" fill="#333333"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3199 16.7911H12.3015C12.3015 16.7911 12.598 16.7554 12.7496 16.5766C12.8889 16.4124 12.8844 16.1043 12.8844 16.1043C12.8844 16.1043 12.8653 14.6612 13.4764 14.4486C14.0792 14.2392 14.8532 15.8432 15.6733 16.4602C16.2936 16.9269 16.7648 16.8248 16.7648 16.8248L18.9582 16.7911C18.9582 16.7911 20.1055 16.7135 19.5614 15.7254C19.5169 15.6446 19.2443 14.9944 17.9306 13.6582C16.5552 12.2598 16.7396 12.486 18.3963 10.0669C19.4051 8.59368 19.8084 7.69451 19.6821 7.3094C19.5621 6.94242 18.8199 7.03926 18.8199 7.03926L16.3504 7.05608C16.2409 7.04185 16.13 7.06328 16.0315 7.11769C15.9378 7.19282 15.8628 7.29269 15.8137 7.40803C15.5579 8.13501 15.253 8.8401 14.9016 9.51746C13.802 11.563 13.3622 11.6714 13.1825 11.5442C12.7643 11.248 12.8688 10.355 12.8688 9.72044C12.8688 7.73771 13.1431 6.91115 12.3343 6.69718C12.0659 6.62617 11.8681 6.57939 11.1816 6.5716C10.3004 6.5622 9.55476 6.5746 9.13274 6.80134C8.85191 6.95163 8.63502 7.2878 8.76712 7.30724C8.93 7.33091 9.2999 7.41638 9.49579 7.7085C9.74893 8.08572 9.74002 8.93276 9.74002 8.93276C9.74002 8.93276 9.88575 11.2665 9.40037 11.5564C9.0675 11.7552 8.61093 11.3497 7.6305 9.49323C7.29777 8.8475 7.00338 8.17894 6.74916 7.49172C6.70392 7.37504 6.63416 7.27173 6.54556 7.19021C6.43075 7.1068 6.30171 7.04973 6.16623 7.02245L3.81942 7.03917C3.81942 7.03917 3.46726 7.04997 3.3379 7.21763C3.22277 7.36726 3.32873 7.67563 3.32873 7.67563C3.32873 7.67563 5.16582 12.3846 7.24636 14.7578C9.15417 16.9337 11.3201 16.791 11.3201 16.791L11.3199 16.7911Z" fill="#DADADA"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "vkTransparent"
})
</script>

<style scoped lang="scss">

</style>
