
import {defineComponent} from 'vue';
import CodeInput from "@/components/base/input/CodeInput.vue";
export default defineComponent({
  name: "Code",
  props: {
    login: {
      type: String,
      required: true
    },
    error: {
      type: String,
      default: (): string => {
        return "";
      }
    }
  },

  components: {
    CodeInput
  },

  emits: ['code', 'changeComponent', 'updateCode'],
  data() {
    return {
      confirmation_code: "",
      timeout: 60
    }
  },
  mounted() {
    this.StartTimer();
  },
  computed: {
    disabled(): boolean {
      return this.confirmation_code.length < 6;
    }
  },
  methods: {
    SendConfirmationCode(): void {
      this.$emit('code', this.confirmation_code);
    },
    UpdateCode(): void {
      this.timeout = 60;
      this.StartTimer();
      this.$emit('updateCode');
    },
    ChangeLogin(): void {
      this.$emit('changeComponent', "login");
    },
    StartTimer(): void {
      let tmp = setInterval(() => {
        this.timeout--;
        if (this.timeout === 0) clearInterval(tmp);
      }, 1000)
    }
  }
})
