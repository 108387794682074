<template>
  <div class="sm:px-5 container mx-auto">
    <div class="footer-topbar border-solid border-b border-blue">
      <div
        class="mx-auto pt-7 pb-10 flex flex-col md:flex-row items-center md:items-start text-center md:text-left justify-between font-semibold text-base">
        <div class="md:w-1/6 lg:w-auto">
          <h4 class="text-gray text-base font-semibold">Меню</h4>
          <ul class="links-list">
            <li class="links-list__item" v-for="link in sitemap" :key="link.id">
              <router-link
                class="hover:border-blue hover:border-b text-white"
                :to="'/' + link.slug"
                active-class="border-b border-blue"
              >
                {{ link.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="md:w-2/6 lg:w-auto text-white">
          <h4 class="text-gray text-base font-semibold">Мы работаем</h4>
          <p>Понедельник - Суббота с 9:00 до 19:00</p>
          <p>Воскресенье с 10:00 до 16:00</p>
        </div>
        <div class="md:w-1/6 lg:w-auto text-white">
          <h4 class="text-gray text-base font-semibold">Связаться с нами</h4>
          <div class=" pb-2 hover:text-blue">
            <a href="tel:8 (938) 514-81-11">
              <font-awesome-icon icon="phone-alt" class="mr-2"/>
              8 (938)514-81-11
            </a>
          </div>
          <div class=" pb-2 hover:text-blue">
            <a href="mailto:office@loft.ug">
              <font-awesome-icon icon="envelope" class="mr-2"/>
              office@loft.ug
            </a>
          </div>
        </div>
        <div class="md:w-1/6 lg:w-auto">
          <h4 class="text-gray text-base font-semibold">Больше новостей</h4>
          <div class="flex my-3 lg:my-auto lg:flex justify-start icons footer-social ">
            <div>
              <span class="pb-2 lg:border-b-0 inline-block w-10 flex vk">
                <a href="https://vk.com/loft_groups">
                  <vk/>
                </a>
              </span>
            </div>
            <div>
              <span class="pb-2 inline-block w-10 flex instagram">
                <a href="https://www.instagram.com/loft_groups/" >
                  <instagram/>
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="md:w-1/6 lg:w-auto">
          <div
            @click="ToggleCallback"
            class="bg-blue text-white rounded flex font-semibold p-3 items-center space-x-3 mb-3 cursor-pointer hover:bg-darkblue">
            <font-awesome-icon icon="phone-alt"/>
            <span> Заказать звонок </span>
          </div>
          <p class="font-medium text-sm leading-none text-gray">
            Оставьте заявку <br/>
            на обратный звонок
          </p>
        </div>
      </div>
    </div>
    <div class="mx-auto py-3 bottom flex flex-wrap flex-col md:flex-row items-center md:items-start justify-between small">
      <div class="policy order-1">
        <router-link to="/policy"> Политика конфиденциальности</router-link>
      </div>
      <div class="copyright order-3 md:order-2">
        <a href=""> © ЛОФТ 2021 </a>
      </div>
      <div class="results my-5 md:my-0 order-2 md:order-3">
        <a target="_blank" :href="`${publicPath}sout.pdf`">
          Результаты проведения СОУТ
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    ToggleCallback() {
      let targets = document.querySelectorAll("#itlcb-step2, #itlcb-bg-fill");
      targets.forEach((target) => {
        target.style.visibility = "visible";
      });
    },
  },
  computed: {
    sitemap(){
      return this.$store.state.site.sitemap;
    }
  },
};
</script>
<style lang="scss">
.footer-social #vk-bg,#inst-bg{
  fill: #fff;
}
.footer-social #inst-circle, .footer-social .icon-part{
  fill: #333;
}
</style>
<style lang="scss" scoped>
footer h4 {
  @apply mb-2;
}

footer .links-list__item a:hover {
  @apply border-b border-blue;
}

footer a:hover svg {
  color: inherit;
}

.links-list__item {
  @apply my-1;
}


@media (max-width: 768px) {
  .icons{
    justify-content: center;
  }
}
</style>
