import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const state = {
  page: 1,
  per_page: ()=>{
    const windowWidth = window.innerWidth;
    let count = 20;
    if(windowWidth <= 1535) count = 18;
    return count;
  },
  sort_by: "price",
  sort_ord: "asc",
  additional_filter_tab: "1",
  offer_property: () => {
    return state.main_filter.offer_property === 1 ? "buy" : "rent";
  },
  main_filter: {
    text_search: "",
    offer_property: 1,
    types: [],
    rooms_count: [],
    area: {
      from: "",
      to: "",
    },
    commerce_types: [],
    lot_area: {
      from: "",
      to: "",
    },
    price: {
      from: "",
      to: "",
    },
    price_sqm: {
      from: "",
      to: "",
    },
  },
  main_filter_data: {},
  additional_filter_data: {},
  additional_filter: {},
  detectedObjects: [],
  colors: [
    { color: "#63E3FF", choosed: false },
    { color: "#17D49B", choosed: false },
    { color: "#6C4DCD", choosed: false },
    { color: "#FF9C8F", choosed: false },
    { color: "#175FD4", choosed: false },
    { color: "#17D4D4", choosed: false },
    { color: "#DB4F82", choosed: false },
    { color: "#DA56D5", choosed: false },
    { color: "#23A5FF", choosed: false },
    { color: "#933ED6", choosed: false },
    { color: "#4E71FD", choosed: false },
    { color: "#6B398F", choosed: false },
    { color: "#707AC1", choosed: false },
    { color: "#E27680", choosed: false },
    { color: "#FF9AE3", choosed: false },
  ],
  old_search: {
    page: 1,
    per_page: 20,
  },
  loading: false,
  error: false,
  errorCode: 502,
  filter_loading: true,
  filter_error: false,
};

export default {
  state,
  mutations,
  getters,
  actions,
};
