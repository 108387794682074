<template>
  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.97952 0.998291L0.00195313 2.98993L6.03281 9.02079L0.0299596 15.0236L2.0216 17.0012L10.002 9.02079L1.97952 0.998291Z" fill="#333333"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "chevronRight"
})
</script>

<style scoped lang="scss">

</style>
