
import {defineComponent} from 'vue';
import PasswordInput from "@/components/base/input/PasswordInput.vue";

export default defineComponent({
  name: "Password",
  props: {
    error: {
      type: String,
      default: (): string => {
        return "";
      }
    }
  },
  components: {PasswordInput},
  emits: ['changeComponent', "authorize", "close"],
  data() {
    return {
      identity_value: ""
    }
  },
  computed: {
    disabled(): boolean {
      return this.identity_value.length < 6
    }
  },
  methods: {
    ChangeLogin(): void {
      this.$emit('changeComponent', "login");
    },
    AuthorizeUser(): void {
      this.$emit("authorize", this.identity_value);
    }
  }
})
