import {refresh_token} from "@/api/auth";

export default {
  RefreshToken({commit}) {
    const token = localStorage.getItem('refresh_token');
    if (token) {
      refresh_token(token)
        .then(response => {
          localStorage.setItem('token', response.data.access);
          localStorage.setItem('refresh_token', response.data['refresh']);
          commit("AuthorizeUser");
        })
        .catch(() => {
          commit("Logout");
        })
    } else {
      commit("Logout");
    }
  }
};
