import api from "@/api/api";
import {id, MapOffersRequest} from "@/typings/types";
import {AxiosResponse} from "axios";

const URLS = {
  filters_data_list: "/server_info/filters_data_list",
  additional_filter: "/server_info/additional_filters",
  offers_all: "/offers/all",
  offers_card: "/offers/list_offers"
};

export function get_filters_data_list(): Promise<AxiosResponse>  {
  return api.get(URLS.filters_data_list);
}

export function get_additional_filter(): Promise<AxiosResponse>  {
  return api.get(URLS.additional_filter);
}

export function get_offers(params: any): Promise<AxiosResponse>  {
  return api.get(URLS.offers_all, {params});
}

export function get_offer_cards_by_id(params: MapOffersRequest): Promise<AxiosResponse>  {
  return api.get(URLS.offers_card, {params})
}
