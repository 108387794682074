import api from "./api.js";
import {AxiosResponse} from "axios";
const URLS = {
  login: "/auth/login/",
  register: "/auth/register",
  logout: "/auth/logout",
  logout_all: "/auth/logout_all",
  refresh: "/auth/login/refresh/",
  confirm_code: "/auth/confirm_identity",
  update_confirmation_code: "/auth/update_confirmation_code",
};

export function login_request(identity_name: string, identity_value: string): Promise<AxiosResponse>  {
  return api.post(URLS.register, {identity_name, identity_value});
}

export function authorize_request(login: string, identity_value: string, identity_name: string = "password"): Promise<AxiosResponse>  {
  return api.post(URLS.login, {login, identity_value, identity_name})
}

export function register_request(params: any): Promise<AxiosResponse>  {
  return api.post(URLS.register, {params});
}

export function refresh_token(refresh: string): Promise<AxiosResponse>  {
  return api.post(URLS.refresh,{refresh})
}

export function confirm_code(identity_name: string="phone",identity_value: string, confirmation_code: string): Promise<AxiosResponse>  {
  return api.post(URLS.confirm_code,{identity_name,identity_value,confirmation_code});
}

export function send_code_again(): Promise<AxiosResponse>  {
  return api.post(URLS.update_confirmation_code)
}
