<template>
  <div class="hidden lg:flex ml-auto lg:ml-0 relative">
    <button
      @click.stop="toggle_modal"
      class="city mr-5 transition-color ease-in-out block duration-300 hover:text-blue"
    >
      <font-awesome-icon class="mr-2" icon="map-marker-alt" />
      <span class="hidden lg:inline">{{ locality_name }}</span>
    </button>
    <div
      class="absolute top-8 left-0 bg-white p-5 shadow-2xl min-w-full"
      v-if="opened"
      v-click-outside="toggle_modal"
    >
      <input
        class="border border-gray p-3 w-full mb-3 rounded"
        placeholder="Введите регион или город"
        type="text"
        v-model="search"
        style="min-width: 200px"
      />
      <div class="city_list" v-if="true">
        <div v-for="region in localities(search)" :key="region">
          <div
            @click="set_locality(region[1])"
            class="text-xl text-black font-semibold hover:text-blue cursor-pointer whitespace-nowrap "
          >
            {{ region[0] }}
          </div>
        </div>
      </div>
      <div v-else>
        Не найдено
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ClickOutside from "@/helpers/clickoutside";

const { mapActions, mapMutations, mapGetters } = createNamespacedHelpers(
  "serverInfo"
);

export default {
  name: "LocationSelect",
  components: {},
  data() {
    return {
      opened: false,
      search: "",
    };
  },
  async mounted() {
    this.FETCH_LOCALITIES();
  },
  computed: {
    ...mapGetters(["getLocalities", "getLocality"]),
    localities() {
      return this.$store.getters["serverInfo/getLocalities"];
    },
    locality_name() {
      let loc = Object.fromEntries(this.getLocalities());
      return Object.keys(loc).find((key) => {
        return loc[key] === this.getLocality.toString();
      });
    },
  },
  methods: {
    ...mapActions(["FETCH_LOCALITIES"]),
    ...mapMutations(["setLocalities", "setLocality"]),
    toggle_modal() {
      this.opened = !this.opened;
    },
    set_locality(value) {
      this.opened = false;
      this.setLocality(value);
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.city_list {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(6, auto);
}
</style>
