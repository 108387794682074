<template>
  <div class="header-mobile">
    <div class="container mx-auto header-mobile__visible-content">
      <div class="header-mobile__visible-content__logo">
        <router-link to="/">
          <logo/>
        </router-link>
      </div>
      <div class="header-mobile__visible-content__menu">
        <div class="header-mobile__visible-content__menu__callback" @click="ToggleCallback">
          <phone/>
        </div>
        <div class="header-mobile__visible-content__menu__add-object">
          <plus/>
        </div>
        <div class="header-mobile__visible-content__menu__favorites">
          <heart/>
        </div>
        <div class="header-mobile__visible-content__menu__auth">
          <AuthorizationDropdown/>
        </div>
        <div class="header-mobile__visible-content__menu__menu-button" @click="ShowMenu">
          <burger/>
        </div>

      </div>
    </div>
    <div class="header-mobile__navigation" v-if="showMenu">
      <div class="header-mobile__navigation__close" @click="HideMenu"><cross fill="#c9c9c9"/></div>
      <div class="header-mobile__navigation__link" v-for="link in sitemap" :key="link.id">
        <router-link @click="HideMenu" :to="'/' + link.slug">{{ link.title }}</router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import AuthorizationDropdown from "@/components/common/Auth/AuthorizationDropdown.vue";
export default defineComponent({
  name: "HeaderMobile",
  components: {
    AuthorizationDropdown
  },
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    sitemap() {
      return this.$store.state.site.sitemap;
    }
  },
  methods: {
    ToggleCallback() {
      let targets = document.querySelectorAll<HTMLElement>("#itlcb-step2, #itlcb-bg-fill");
      targets.forEach(target => {
        target.style.visibility = "visible";
      })
    },
    ShowMenu(): void {
      this.showMenu = true;
    },
    HideMenu(): void {
      this.showMenu = false
    }
  }
})
</script>

<style scoped lang="scss">
.header-mobile {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #FFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 54px;
  padding: 12px 1.25rem;

  &__visible-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__menu {
      display: flex;
      align-items: center;

      &__callback {
        margin-right: 25px;
        width: 30px;
        height: 30px;
        background: #3D7E9C;
        border-radius: 3.19149px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
      }
      &__auth{
        margin-right: 20px;
      }
      &__add-object {
        margin-right: 25px;
      }

      &__favorites {
        margin-right: 25px;
      }
    }
  }

  &__navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 30px 15px;
    &__close{
      position: absolute;
      right: 15px;
      top: 10px;
    }
    &__link {
      font-size: 20px;
      color: #333;
      border-bottom: 1px solid #C9C9C9;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
