import getters from "./serverInfoGetters";
import actions from "./serverInfoActions";
import mutations from "./serverInfoMutations";

const state = {
  locality: 23000001000,
  localities: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
