import { render } from "./ReturnToTop.vue?vue&type=template&id=74b2ccee&scoped=true"
import script from "./ReturnToTop.vue?vue&type=script&lang=js"
export * from "./ReturnToTop.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./ReturnToTop.vue?vue&type=style&index=0&id=74b2ccee&scoped=true&module=true&lang=scss"
cssModules["$style"] = style0
script.render = render
script.__scopeId = "data-v-74b2ccee"

export default script