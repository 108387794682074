<template>
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 1L1 10.5" stroke="#333333"/>
    <path d="M10.5 10.5L1 1" stroke="#333333"/>
  </svg>

</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "modalCross"
})
</script>

<style scoped lang="scss">

</style>
