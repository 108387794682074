
import {defineComponent} from 'vue';

export default defineComponent({
  name: "PasswordInput",
  props: {
    placeholder: {
      type: String,
      default: (): string => {
        return "";
      }
    },
    modelValue: {
      type: String,
      default: (): string => {
        return "";
      }
    },
    error: {
      type: String,
      default: (): string => {
        return "";
      }
    }
  },
  data() {
    return {
      hide: true
    }
  },
  computed: {
    type(): string {
      return this.hide ? "password" : "text";
    },
    icon(): string {
      return this.hide ? "eye-slash" : "eye";
    }
  },
  methods: {
    Update(e: InputEvent): void {
      const value = (e.target as HTMLInputElement).value;
      this.$emit('update:modelValue', value);
    },
    ToggleHide(): void {
      this.hide = !this.hide;
    }
  }
})
