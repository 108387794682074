import api from "./api";
import {AxiosResponse} from "axios";

const URLS = {
  sitemap: "/sitemap/get_sitemap",
  location: "/server_info/get_regions_and_locations",
  companies: "/companies/get_company_info",
  send_appeal: "/companies/send_appeal",
};

export function fetch_sitemap(): Promise<AxiosResponse> {
  return api.get(URLS.sitemap)
}

export function fetch_locations(args: any): Promise<AxiosResponse> {
  return api.get(URLS.location,{params:{...args}})
}

export function fetch_companies(city_code: string): Promise<AxiosResponse> {
  return api.get(`${URLS.companies}?city_code=${city_code}`)
}

export function send_appeal(data: any): Promise<AxiosResponse> {
  return api.post(URLS.send_appeal,data)
}
