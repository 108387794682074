let keys: any = {
  house_type: "Тип дома",
  additional: "Дополнительно",
  equipment: "Оборудование",
  commerce_type: "Тип коммерции",
  category: "Тип дома",
  price: "Цена",
  price_sqm: "Цена за м2",
  offer_property: "Тип недвижимости",
  types: "Тип недвижимости",
  commerce_types: "Тип коммерции",
  floors_compiled: "Этаж",
  area_normalized: "Общая площадь",
  rooms_count: "Количество комнат",
  area: "Общая площадь",
  floor: "Этаж",
  floors_total: "Этажность",
  payment_types: "Способы оплаты",
  built_year: "Год постройки",
  renovation: "Ремонт",
  living_area: "Жилая площадь",
  kitchen_area: "Площадь кухни",
  bathroom_area: "Площадь ванной",
  ceiling_height: "Высота потолков",
  bathroom_unit: "Санузел",
  balcony: "Балкон",
  window_view: "Вид",
  wardrobe: "Гардеробная",
  wall_material: "Материал стен",
  house_lot_area: "Площадь участка",
  lot_area: "Площадь участка",
  water_supply: "Водоснабжение",
  electricity_supply: "Электроснабжение",
  gas_supply: "Газоснобжение",
  sewerage_supply: "Канализация",
  heating_supply: "Отопление",
  facade_length: "Длина фасада",
  lot_form: "Форма участка",
  sauna: "Сауна",
  security: "Охрана",
  garage: "Гараж",
  pool: "Бассейн",
  driveways: "Подъездные пути",
  lot_property: "Назначение участка",
  lot_width: "Ширина участка",
  on_corner: "Угловой",
  lift: "Лифт",
  finished_at: "Дата сдачи",
  isolated_cabinets: "Кол-во изолированных кабинетов",
  with_tenant: "С арендодатором",
  utilities: "Коммуникации",
  parking: "Парковка",
  distance_to_city: "Расстояние до города",
  loading_area: "Зона погрузки",
  hotel_rooms_count: "Количество номеров",
  profitability: "Доходность",
  legal_entity: "Юр.лицо",
  furniture: "Мебель",
  section_count: "Количество литеров",
  developer_name: "Застройщик",
  flats_per_floor: "Квартир на этаже",
  living_class: "Класс жилья",
  building_state: "Стадия строительсва дома"
};
export function GetNameByKey(key: string): string {
  return keys[key];
}
