<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 0C3.36418 0 0 3.36418 0 7.5C0 11.6358 3.36418 15 7.5 15C11.6358 15 15 11.6358 15 7.5C15 3.36418 11.6358 0 7.5 0ZM11.1058 8.07692H8.07692V11.25C8.07692 11.5673 7.82091 11.8269 7.5 11.8269C7.18269 11.8269 6.92308 11.5673 6.92308 11.25V8.07692H3.89423C3.57692 8.07692 3.31731 7.82091 3.31731 7.5C3.31731 7.17909 3.57692 6.92308 3.89423 6.92308H6.92308V4.03846C6.92308 3.72115 7.18269 3.46154 7.5 3.46154C7.81731 3.46154 8.07692 3.71755 8.07692 4.03846V6.92308H11.1058C11.4231 6.92308 11.6827 7.18269 11.6827 7.5C11.6827 7.81731 11.4231 8.07692 11.1058 8.07692Z" fill="#C9C9C9"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "plus"
})
</script>

<style scoped lang="scss">

</style>
