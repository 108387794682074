<template>
  <button
    :id="id"
    :class="['btn-' + size, { 'btn-outline': outline }]"
    class="btn focus:outline-none"
  >
    <slot/>
  </button>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "s",
    },
    outline: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
};
</script>

<style lang="scss">
.btn {
  color: white;
  border-radius: 4px;
  @apply bg-blue;
  &:hover {
    background-color: #00afea;
    @apply bg-darkblue;
  }

  &:active {
    @apply bg-darkblue;
  }
}

.btn-s {
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 16px;
}

.btn-m {
  padding: 22px 58px;
  font-size: 18px;
}

.btn-l {
  padding: 26px 72px;
  font-size: 20px;
}

.btn-sm {
  padding: 5px 10px;
}

.btn-full {
  width: 100%;
  padding: 5px 10px;
}

.btn-xsm {
  padding: 8px;
}

.btn-auth {
  font-style: normal;
  font-weight: 600;
  font-size: 14.3784px;
  line-height: 150%;
  padding: 8px 0;
  width: 100%;
  @apply text-white bg-blue;
  &:disabled{
    color: #828282;
    background: #DADADA;
  }
}

.btn-login-type {
  height: 38px;
  width: 248px;
  font-weight: 600;
  font-size: 14.3784px;
}

.btn-outline {
  background-color: white;
  @apply text-blue;
  &:hover {
    background-color: #e0f5fc;
  }

  &:active {
    background-color: #e0f5fc;
  }
}
</style>
