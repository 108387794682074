
import {defineComponent} from 'vue';
import ClickOutside from "@/helpers/clickoutside.js";
export default defineComponent({
  name: "AuthorizationDropdown",
  data() {
    return {
      show: false
    }
  },
  computed: {
    isAuth(): boolean {
      return this.$store.state.user.isAuth;
    },
    icon(): string {
      return this.isAuth ? "user" : "sign-in-alt"
    }
  },
  methods: {
    ShowList(): void {
      this.show = true;
    },
    HideList(): void {
      this.show = false
    },
    ShowAuthModal(): void {
      this.HideList()
      this.$store.commit('site/SHOW_AUTH');
    },
    Logout(): void {
      this.HideList()
      this.$store.commit('user/Logout');
    },
    To(routeName: string): void {
      this.HideList()
      this.$router.push({name: routeName})
    }
  },
  directives: {
    ClickOutside
  }
})
