export default {
  AuthorizeUser(state, payload) {
    state.isAuth = true;
    state.checkingAuth = false;
    if (payload)
      state.User = payload;
  },
  Logout(state) {
    state.isAuth = false;
    state.checkingAuth = false;
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
  }
};
