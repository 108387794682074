import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  objects_view_style: "grid",
  showAuth: false,
  sitemap: [],
  sitemapLoading: true,
  sitemapError: false,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
