import { GetNameByKey } from "../../../ts-helpers/GetNameByKey";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
export default {
  GET_TYPES_INCLUDES_NAME: (state) => (names) => {
    let offer_property = state.offer_property();
    let show = false;
    names.forEach((name) => {
      let num = state.main_filter_data[`objects_types_${offer_property}`].find(
        (type) => type.name.toUpperCase() === name.toUpperCase()
      );
      if (
        num &&
        state.main_filter.types &&
        state.main_filter.types.includes(num.value.toString())
      )
        show = true;
    });
    return show;
  },
  GET_TYPE_KEY_BY_NAME: (state) => (name) => {
    return state.main_filter_data.objects_types_buy.find(
      (type) => type.name.toUpperCase() === name.toUpperCase()
    ).value;
  },
  GET_CURRENT_TAB_ADDITIONAL_FILTER(state) {
    let offer_property = state.offer_property();
    let tab = state.additional_filter_data[offer_property];
    if (typeof tab === "undefined") return [];
    else
      return tab.filter(
        (item) =>
          state.main_filter.types.includes(item.tab_value) ||
          state.main_filter.commerce_types.includes(item.tab_value)
      );
  },
  GET_SPLIT_INPUT_DATA: (state) => (payload) => {
    if (state.additional_filter[payload.tab][payload.name])
      return (
        state.additional_filter[payload.tab][payload.name][payload.type] ?? ""
      );
  },
  GET_TAGS(state) {
    let res = [];
    state.colors.forEach((col) => (col.choosed = false));

    function getColor() {
      let color = state.colors.filter((col) => col.choosed === false)[0].color;
      state.colors.filter((col) => col.choosed === false)[0].choosed = true;
      return color;
    }

    Object.keys(state.main_filter).map((key) => {
      switch (key) {
        case "offer_property": {
          break;
        }
        case "price": {
          let value = "";
          if (state.main_filter[key].from && state.main_filter[key].to) {
            value = `от ${state.main_filter[key].from + " ₽"} до ${
              state.main_filter[key].to + " ₽"
            }`;
          } else if (state.main_filter[key].from)
            value = state.main_filter[key].from + " ₽";
          else if (state.main_filter[key].to)
            value = state.main_filter[key].to + " ₽";
          if (value !== "")
            res.push({
              name: "Цена",
              value: value,
              color: getColor(),
              inObj: "main_filter",
              key: key,
            });
          break;
        }
        case "price_sqm": {
          let value = "";
          if (state.main_filter[key].from && state.main_filter[key].to)
            value = `от ${state.main_filter[key].from + " ₽"} до ${
              state.main_filter[key].to + " ₽"
            }`;
          else if (state.main_filter[key].from)
            value = state.main_filter[key].from + " ₽";
          else if (state.main_filter[key].to)
            value = state.main_filter[key].to + " ₽";

          if (value !== "")
            res.push({
              name: "Цена за м2",
              value: value,
              color: getColor(),

              inObj: "main_filter",
              key: key,
            });
          break;
        }
        case "area": {
          let value = "";
          if (state.main_filter[key].from && state.main_filter[key].to)
            value = `от ${state.main_filter[key].from + "м2"} до ${
              state.main_filter[key].to + "м2"
            }`;
          else if (state.main_filter[key].from)
            value = state.main_filter[key].from + " м2";
          else if (state.main_filter[key].to)
            value = state.main_filter[key].to + " м2";

          if (value !== "")
            res.push({
              name: "Площадь до",
              value: value,
              color: getColor(),

              inObj: "main_filter",
              key: key,
            });
          break;
        }
        case "lot_area": {
          let value = "";
          if (state.main_filter[key].from && state.main_filter[key].to)
            value = `от ${state.main_filter[key].from + "сот."} до ${
              state.main_filter[key].to + "сот."
            }`;
          else if (state.main_filter[key].from)
            value = state.main_filter[key].from + " сот.";
          else if (state.main_filter[key].to)
            value = state.main_filter[key].to + " сот.";

          if (value !== "")
            res.push({
              name: "Сотки до",
              value: value,
              color: getColor(),

              inObj: "main_filter",
              key: key,
            });
          break;
        }
        case "rooms_count": {
          let names = [];
          state.main_filter[key].map((item) => {
            let _type = state.main_filter_data.rooms_choices.find(
              (itm) => itm.value === item
            );
            names.push(_type.name);
          });
          if (names.length)
            res.push({
              name: "Количество комнат:",
              value: names.join(", "),

              color: getColor(),
              inObj: "main_filter",
              key: key,
            });
          break;
        }
        case "text_search": {
          if (state.main_filter[key] !== "")
            res.push({
              name: "Поиск",
              value: state.main_filter[key],
              key: key,
              inObj: "main_filter",
              color: getColor(),
            });
          break;
        }
        case "types": {
          state.main_filter[key].map((item) => {
            let _type = state.main_filter_data[
              `objects_types_${state.offer_property()}`
            ].find((itm) => itm.value === item);
            let add_filter = state.additional_filter[_type.value];
            let sub_fields = [];
            Object.keys(add_filter).map((key2) => {
              let field_data = state.additional_filter_data[
                state.offer_property()
              ]
                .find((type) => type.tab_value === _type.value)
                .fields.find((field) => field.field_value === key2);
              let name = field_data.field_name + ":";
              let value = "";
              let inObj = "additional_filter";
              if (field_data)
                switch (field_data.component_type) {
                  case "splitinput": {
                    let unit = field_data.values[1].name ?? "";
                    if (add_filter[key2].from && add_filter[key2].to) {
                      value = `от ${add_filter[key2].from} ${unit} до ${add_filter[key2].to} ${unit}`;
                    } else if (add_filter[key2].from) {
                      value = `от ${add_filter[key2].from} ${unit}`;
                    } else if (add_filter[key2].to) {
                      value = `до ${add_filter[key2].to} ${unit}`;
                    }
                    if (key2 === "floor") {
                      if (add_filter[key2].not_first) {
                        value = value + " не первый;";
                      }
                      if (add_filter[key2].not_last) {
                        value = value + " не последний;";
                      }
                    }
                    break;
                  }
                  case "radio": {
                    const radio_values = field_data.values.filter(
                      (field_val) => field_val.value === add_filter[key2]
                    );
                    if (radio_values.length) value = radio_values[0].name;
                    break;
                  }
                  case "multiselect": {
                    let local_value = [];
                    add_filter[key2].map((selectItem) => {
                      local_value.push(
                        field_data.values.find(
                          (val) => val.value === selectItem
                        ).name
                      );
                    });
                    value = local_value.join(", ");
                    break;
                  }
                }
              if (value)
                sub_fields.push({
                  name,
                  value: value.toLowerCase(),
                  inObj,
                  key: "additional_filter",
                  field_to_delete_in: item,
                  field_to_delete: key2,
                });
            });
            res.push({
              name: _type.name.capitalize(),
              sub_fields: sub_fields,
              color: getColor(),
              field_to_delete: item,
              inObj: "main_filter",
              key: key,
            });
          });
          break;
        }
        case "commerce_types": {
          state.main_filter[key].map((item) => {
            let _type = state.main_filter_data.commercial_types.find(
              (itm) => itm.value === item
            );
            let add_filter = state.additional_filter[_type.value];
            let sub_fields = [];

            Object.keys(add_filter).map((key2) => {
              let field_data = state.additional_filter_data[
                state.offer_property()
              ]
                .find((type) => type.tab_value === _type.value)
                .fields.find((field) => field.field_value === key2);
              let name = field_data.field_name + ":";
              let value = "";
              let inObj = "additional_filter";
              switch (field_data.component_type) {
                case "splitinput": {
                  let unit = field_data.values[1].name ?? "";

                  if (add_filter[key2].from && add_filter[key2].to) {
                    value = `от ${add_filter[key2].from} ${unit} до ${add_filter[key2].to} ${unit}`;
                  } else if (add_filter[key2].from) {
                    value = `от ${add_filter[key2].from} ${unit}`;
                  } else if (add_filter[key2].to) {
                    value = `до ${add_filter[key2].to} ${unit}`;
                  }

                  break;
                }
                case "radio": {
                  const radio_values = field_data.values.filter(
                    (field_val) => field_val.value === add_filter[key2]
                  );
                  if (radio_values.length) value = radio_values[0].name;
                  break;
                }
                case "multiselect": {
                  let local_value = [];
                  add_filter[key2].map((selectItem) => {
                    local_value.push(
                      field_data.values.find((val) => val.value === selectItem)
                        .name
                    );
                  });
                  value = local_value.join(", ");
                  break;
                }
              }
              if (value)
                sub_fields.push({
                  name,
                  value: value.toLowerCase(),
                  inObj,
                  key: "additional_filter",
                  field_to_delete_in: item,
                  field_to_delete: key2,
                });
            });
            res.push({
              name: _type.name.capitalize(),
              key: key,
              sub_fields: sub_fields,
              color: getColor(),
              field_to_delete: _type.value,
              inObj: "main_filter",
            });
          });
          break;
        }
        default: {
          res.push({
            name: GetNameByKey(key),
            value: state.main_filter[key],
            key: key,
            color: getColor(),
          });
          break;
        }
      }
    });
    return res;
  },
  GET_ADDITIONAL_FILTER(state) {
    return state.additional_filter;
  },
  GET_ADDITIONAL_FILTER_RAW(state) {
    return state.additional_filter_data;
  },
  GET_OFFER_PROPERTY(state) {
    return state.offer_property();
  },
  GET_CURRENT_TAB(state) {
    return state.additional_filter_tab;
  },
  GET_INPUT_VALUE: (state) => (tab, field, type) => {
    if (!state.additional_filter[tab][field]) {
      let val = "";
      switch (type) {
        case "splitinput": {
          val = { from: "", to: "" };
          if (field === "floor")
            val = { from: "", to: "", not_last: false, not_first: false };
          break;
        }
        case "multiselect": {
          val = [];
          break;
        }
        case "radio": {
          val = "";
          break;
        }
      }
      state.additional_filter[tab][field] = val;
    }
    return state.additional_filter[tab][field];
  },
  GET_TEXT_SEARCH(state) {
    return state.main_filter.text_search;
  },
};
