<template>
  <div class="password-modal">
    <div class="flex items-center justify-between mb-4">
      <div class="password-modal__title">Войти</div>
      <div class="password-modal__change-login" @click="ChangeLogin">Изменить номер</div>
    </div>
    <div class="password-modal__input">
      <password-input v-model="identity_value" placeholder="Введите пароль" :error="error"/>
    </div>
    <div class="password-modal__button">
      <base-button :disabled="disabled" size="auth" @click="AuthorizeUser">Продолжить</base-button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import PasswordInput from "@/components/base/input/PasswordInput.vue";

export default defineComponent({
  name: "Password",
  props: {
    error: {
      type: String,
      default: (): string => {
        return "";
      }
    }
  },
  components: {PasswordInput},
  emits: ['changeComponent', "authorize", "close"],
  data() {
    return {
      identity_value: ""
    }
  },
  computed: {
    disabled(): boolean {
      return this.identity_value.length < 6
    }
  },
  methods: {
    ChangeLogin(): void {
      this.$emit('changeComponent', "login");
    },
    AuthorizeUser(): void {
      this.$emit("authorize", this.identity_value);
    }
  }
})
</script>

<style lang="scss" scoped>
.password-modal {
  height: 243px;
  width: 308px;
  background: #FFF;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  position: fixed;
  transform: translate(-50%, 0);
  top: 30%;
  left: 50%;
  padding: 30px;

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  &__title {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 100%;
    @apply text-black;
  }

  &__change-login {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100.9%;
    text-decoration-line: underline;
    cursor: pointer;
    @apply text-blue;
  }

  &__input {
    margin-bottom: 10px;
  }

  &__button {
    margin-bottom: 10px;
  }

  &__policy {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 115%;
    color: #B4B4B4;

    a {
      @apply text-blue;
    }
  }
}
</style>
