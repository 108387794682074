
import {defineComponent} from 'vue';

export default defineComponent({
  name: "filterIcon",
  props: {
    fill: {
      type: String,
      default: ():string =>{
        return "#C9C9C9";
      }
    }
  }
})
