<template>
  <svg width="17" height="15" viewBox="0 0 17 15" fill="#C9C9C9" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.50859 0C5.42964 0 6.27407 0.292168 7.01838 0.868407C7.73197 1.42085 8.20706 2.12449 8.48676 2.63615C8.76645 2.12446 9.24155 1.42085 9.95513 0.868407C10.6994 0.292168 11.5439 0 12.4649 0C15.0352 0 16.9735 2.10455 16.9735 4.89538C16.9735 7.91044 14.5553 9.97331 10.8946 13.0962C10.2729 13.6265 9.56829 14.2277 8.83591 14.8688C8.73937 14.9534 8.61538 15 8.48676 15C8.35813 15 8.23414 14.9534 8.13761 14.8688C7.40516 14.2276 6.70056 13.6265 6.07854 13.0959C2.41816 9.97331 1.90735e-06 7.91044 1.90735e-06 4.89538C1.90735e-06 2.10455 1.9383 0 4.50859 0Z"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "heart"
})
</script>

<style scoped lang="scss">

</style>
