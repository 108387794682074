
import {defineComponent, PropType} from "vue";
import ClickOutside from "@/helpers/clickoutside";

interface Select {
  name: string;
  value: string | number;
}

export default defineComponent({
  name: "BaseSelect",
  props: {
    data: {
      type: [Object] as PropType<Select[]>,
      required: true
    },
    label: {
      type: String,
      default: (): string => {
        return "Выбор";
      }
    },
    modelValue: {
      type: String || Number,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    isSelected(value: string | number): boolean {
      return this.modelValue === value;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    openDropdown() {
      this.isOpen = true;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    select(value: string | number) {
      this.isOpen = false;
      this.$emit("valueSelect", value);
    },
  },
  directives: {
    ClickOutside,
  },
});
