<template>
  <svg
    width="22"
    height="13"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.05041e-06 9.73632L2.64756 12.7368L11.0754 5.0554L19.464 12.7011L22 9.77063L11.0754 -2.41998e-05L1.05041e-06 9.73632Z"
      fill="#333333"
    />
    <rect
      x="19.6839"
      y="1.76666"
      width="6.39766"
      height="3.80788"
      transform="rotate(89.7118 19.6839 1.76666)"
      fill="#333333"
    />
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "house"
})
</script>

<style scoped lang="scss">

</style>
