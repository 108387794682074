<template>
  <div class="footer-mobile">
    <div class="container mx-auto footer-mobile__top">
      <div class="footer-mobile__top__callback">
        <button class="footer-mobile__top__callback__button" @click="ToggleCallback">Заказать звонок</button>
        <p class="footer-mobile__top__callback__text">Оставьте заявку на обратный звонок</p>
      </div>
      <div class="footer-mobile__top__menu">
        <div class="footer-mobile__top__menu__link" v-for="link in sitemap" :key="link.id">
          <router-link :to="'/' + link.slug">{{ link.title }}</router-link>
        </div>
      </div>
      <div class="footer-mobile__top__work-time">
        <div class="footer-mobile__top__work-time__label">Мы работаем</div>
        <div class="footer-mobile__top__work-time__time">
          Понедельник - Суббота с 9:00 до 19:00 <br>
          Воскресенье с 10:00 до 16:00
        </div>
      </div>
      <div class="footer-mobile__top__contact">
        <div class="footer-mobile__top__contact__label">Связаться с нами</div>
        <a href="tel:8 (938) 514-81-11" class="footer-mobile__top__contact__link">
          <phone class="mr-2"/>
          8 (938) 514-81-11</a>
        <a href="mailto:office@loft.ug" class="footer-mobile__top__contact__link">
          <mail class="mr-2"/>
          office@loft.ug</a>
      </div>
      <div class="footer-mobile__top__social">
        <div class="footer-mobile__top__social__label">Больше новостей</div>
        <a href="https://m.vk.com/loft_groups" class="footer-mobile__top__social__item">
          <vk-white/>
        </a>
        <a href="https://www.instagram.com/loft_groups/" class="footer-mobile__top__social__item">
          <instagram-white/>
        </a>
      </div>
    </div>
    <div class="container mx-auto footer-mobile__bottom">
      <a class="footer-mobile__bottom__text" href="/policy">Политика конфиденциальности</a>
      <a class="footer-mobile__bottom__text" href="/sout.pdf">Результаты проведения СОУТ</a>
      <p class="footer-mobile__bottom__text">© ЛОФТ 2021</p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';


export default defineComponent({
  name: "footerMobile",
  computed: {
    sitemap() {
      return this.$store.state.site.sitemap;
    }
  },
  methods: {
    ToggleCallback() {
      let targets = document.querySelectorAll<HTMLElement>("#itlcb-step2, #itlcb-bg-fill");
      targets.forEach((target) => {
        target.style.visibility = "visible";
      });
    },
  }
})
</script>

<style scoped lang="scss">
.footer-mobile {
  background: #333;

  &__top {
    padding: 25px 15px;
    border-bottom: 1px solid #3D7E9C;

    &__callback {
      margin-bottom: 30px;

      &__button {
        height: 37px;
        width: 100%;
        background: #3D7E9C;
        border-radius: 3.52998px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 173%;
        color: #FFF;
      }

      &__text {
        margin-top: 8px;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 108%;
        color: #8E8A88;
      }
    }

    &__menu {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 30px;

      &__link {
        width: 50%;
        margin-bottom: 9px;

        a {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #B9B9B9;

          &.active {
            color: #FFF;
            border-bottom: 1px solid #3D7E9C;
          }
        }

        &:nth-child(2n + 2) {
          text-align: right;
        }
      }
    }

    &__work-time {
      margin-bottom: 30px;

      &__label {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #8E8A88;
        margin-bottom: 9px;
      }

      &__time {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01em;
        color: #FFF;
        margin-bottom: 9px;
      }
    }

    &__contact {
      margin-bottom: 30px;

      &__label {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #8E8A88;
        margin-bottom: 19px;
      }

      &__link {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 15px;
        line-height: 173%;
        color: #FFF;
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__social {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 25px;

      &__label {
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #8E8A88;
        margin-bottom: 19px;
      }

      &__item {
        display: block;
        margin-right: 15px;
      }
    }
  }

  &__bottom {
    padding: 17px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #8E8A88;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
