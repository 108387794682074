<template>
  <div class="flex items-center justify-between">
    <input
      type="checkbox"
      :checked="checked"
      @change="
        $emit('changeValue', { value: $event.target.checked, type: name })
      "
      class="field hidden"
      :class="{ checked: checked }"
      :id="name"
    />
    <label
      v-if="label"
      :for="name"
      class="relative cursor-pointer p-0 text-black flex items-center text-sm"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    checked: Boolean,
  },
};
</script>

<style lang="scss" scoped>
input {
  + label::before {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    vertical-align: text-top;
    content: "";
    background: white;
    border: 1px solid #8f8f8f;
    border-radius: 2px;
  }

  &:focus + label::before {
    @apply border-blue;
  }

  &.checked + label::before {
    @apply bg-blue border-blue;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label::before {
    background: #ddd;
    box-shadow: none;
  }

  &.checked + label::after {
    position: absolute;
    top: 7px;
    left: 3px;
    width: 9px;
    height: 7px;
    content: "";
    background-image: url("~@/assets/images/checked.svg");
  }

}
</style>
