<template>
  <svg width="21" height="9" viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="20.9735" y="3" width="20" height="3" transform="rotate(-180 20.9735 3)" fill="#C9C9C9"/>
    <rect x="20.9735" y="9" width="20" height="3" transform="rotate(-180 20.9735 9)" fill="#C9C9C9"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "burger"
})
</script>

<style scoped lang="scss">

</style>
