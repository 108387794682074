<template>
  <div class="text-mediumgray text-sm font-normal lg:px-0">
    <div class="header-bottom py-3">
      <div class="lg:px-5 mx-auto flex justify-between items-center relative">
        <!--    left side large-window    -->
        <router-link
          :class="$style.for_large"
          class="logo flex-none absolute left-10"
          to="/"
        >
          <img alt="" src="../../assets/loft.svg"/>
        </router-link>
        <!--    default    -->
        <div class="container mx-auto">
          <div class="flex w-full items-center relative">
            <router-link
              :class="$style.for_small"
              class="logo flex-none mr-7 pl-5 sm:pl-0"
              to="/"
            >
              <img alt="" src="../../assets/loft.svg"/>
            </router-link>
            <LocationSelect/>
            <nav class="flex-grow hidden lg:flex">
              <ul
                class="flex font-semibold text-black text-sm"
              >
                <li v-for="link in sitemap" :key="link.id" class="mr-7">
                  <router-link
                    :to="{path: '/' + link.slug}"
                    active-class="border-b border-blue"
                    class="hover:text-blue transition-color ease-in-out duration-300"
                  >
                    {{ link.title }}
                  </router-link>
                </li>
              </ul>
            </nav>
            <div :class="$style.for_small" class="ml-auto">
              <div class="ml-auto lg:ml-0 flex h-full items-center">
                <base-button id="callback" size="sm" @click="ToggleCallback">
                  <font-awesome-icon class="mr-1 text-sm" icon="phone-alt"/>
                  <span>Заказать звонок</span>
                </base-button>
                <a
                  class="
                    favorite
                    transition-color
                    ease-in-out
                    block
                    duration-300
                    mr-5
                    hover:text-blue
                  "
                  href="/#"
                >
                  <font-awesome-icon class="mr-2" icon="heart"/>
                </a>
                <a
                  class="
                    add
                    mr-5
                    transition-color
                    ease-in-out
                    block
                    duration-300
                    hover:text-blue
                  "
                  href="/#"
                >
                  <font-awesome-icon class="mr-2" icon="plus-circle"/>
                  <span class="hidden lg:inline">Разместить объект</span>
                </a>
                <AuthorizationDropdown/>
              </div>
            </div>
          </div>
        </div>
        <!--    right side large-window    -->
        <div class="ml-auto lg:ml-0 flex h-full items-center absolute right-10">
          <div :class="$style.for_large">
            <div class="flex items-center">
              <base-button id="callback" size="sm" @click="ToggleCallback">
                <font-awesome-icon class="mr-1 text-sm" icon="phone-alt"/>
                <span>Заказать звонок</span>
              </base-button>
              <a
                class="favorite text-mediumgray transition-color ease-in-out block duration-300 mr-5 hover:text-blue"
                href="/#"
              >
                <font-awesome-icon class="mr-2" icon="heart"/>
              </a>
              <a
                class="add mr-5 text-mediumgray transition-color ease-in-out block duration-300 hover:text-blue"
                href="/#"
              >
                <font-awesome-icon class="mr-2" icon="plus-circle"/>
                <span class="hidden lg:inline">Разместить объект</span>
              </a>
              <AuthorizationDropdown/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationSelect from "@/components/common/Other/LocationSelect";
import BaseButton from "@/components/base/button/BaseButton";
import AuthorizationDropdown from "@/components/common/Auth/AuthorizationDropdown";
export default {
  components: {
    LocationSelect,
    BaseButton,
    AuthorizationDropdown
  },
  methods: {
    ToggleCallback() {
      let targets = document.querySelectorAll("#itlcb-step2, #itlcb-bg-fill");
      targets.forEach(target => {
        target.style.visibility = "visible";
      })
    },
  },
  computed: {
    sitemap() {
      return this.$store.state.site.sitemap;
    },
    isAuth() {
      return this.$store.state.user.isAuth;
    }
  },
};
</script>

<style lang="scss" scoped>
#callback {
  margin-right: 50px;
  @media (max-width: 400px) {
    span {
      display: none;
    }
    svg {
      margin-right: 0;
    }
  }
}
</style>
<style lang="scss" module scoped>
.burgerContainer {
  width: 18px;
  height: 23px;
  padding: 5px;
  margin-right: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg);
  -webkit-tap-highlight-color: transparent;
  @apply relative inline-block lg:hidden cursor-pointer select-none;
  #burger {
    width: 22px;
    height: 22px;
    margin: -4px auto 0;
    @apply top-1/2 block relative cursor-pointer;
    .bar {
      width: 100%;
      height: 2px;
      background: #828282;
      transition: all 0.3s ease-in-out;
      transition-delay: 0s;
      @apply block relative w-full;
      &.topBar {
        transform: translateY(0) rotate(0deg);
      }

      &.btmBar {
        transform: translateY(6px) rotate(0deg);
      }
    }
  }
}

.menuOpened {
  top: 13px;
  right: 0;
  transform: rotate(90deg);
  @apply z-20 fixed;
  ~ a {
    margin-left: 38px;
  }

  #burger {
    .bar {
      transition: all 0.4s ease-in-out;
      transition-delay: 0.2s;

      &.topBar {
        transform: translateY(5px) rotate(45deg) !important;
      }

      &.btmBar {
        transform: translateY(3px) rotate(-45deg) !important;
      }
    }
  }
}

.menuWrapper {
  @apply left-0 top-0 right-0 bottom-0 z-10 w-screen h-screen bg-white fixed;
}

.menu {
  @apply p-5 pt-16 list-none;
  &Item {
    @apply text-black font-semibold border-b border-solid border-lightgray mb-5 pb-3 text-lg;
    a {
      color: inherit;
      text-decoration: none;

      > &.active {
        @apply text-blue;
      }
    }
  }
}

.profile {
  @apply h-full items-center flex;
}

.profile__expand {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  width: 200px;
  @apply bg-milk text-right;
  ul {
    li {
      padding: 20px;
      transition: all ease-in-out 0.2s;
      @apply hover:bg-lightgray hover:text-black cursor-pointer;
    }
  }
}

.profile:hover .profile__expand {
  display: block;
}

.for_large {
  display: none;

  @media (min-width: 1820px) {
    display: block;
  }
}

.for_small {
  display: none;

  @media (max-width: 1820px) {
    display: block;
  }
}
</style>
