<template>
  <div v-if="!loading" :class="$style.wrapper">
    <header>
      <Header />
    </header>
    <div :class="$style['layout-header-mobile']">
      <header-mobile/>
    </div>
    <main>
      <slot />
      <ReturnToTop />
    </main>
    <div :class="$style['layout-footer-mobile']">
      <FooterMobile/>
    </div>
    <footer :class="$style.footer">
      <Footer />
    </footer>
    <CookieNotice
      @close="isCookieNoticeShown = false"
      v-if="isCookieNoticeShown"
    />
    <Authorization v-if="showAuth"/>
  </div>
  <SiteLoading v-else />
</template>

<script>
import Footer from "./components/Footer.vue";
import FooterMobile from "@/layout/components/FooterMobile";
import Header from "./components/Header.vue";
import HeaderMobile from "@/layout/components/HeaderMobile";
import CookieNotice from "@/layout/components/CookieNotice";
import ReturnToTop from "@/components/common/Other/ReturnToTop";
import SiteLoading from "@/components/base/loaders/SiteLoading";
import Authorization from "@/components/common/Auth/Authorization";
export default {
  components: {
    Header,
    HeaderMobile,
    Footer,
    FooterMobile,
    CookieNotice,
    ReturnToTop,
    SiteLoading,
    Authorization
  },
  data() {
    return {
      isCookieNoticeShown: true,

    };
  },
  mounted() {
    const TMP = setInterval(() => {
      const MegafonButton = document.querySelector("#itlcb-call-btn");
      if(MegafonButton) {
        MegafonButton.style = "opacity: 0;pointer-events: none;display: none";
        clearTimeout(TMP)
      }
    }, 40);


  },
  computed: {
    loading() {
      return this.$store.state.search.filter_loading || this.$store.state.user.checkingAuth;
    },
    showAuth() {
      return this.$store.state.site.showAuth;
    }
  },
};
</script>

<style module lang="scss">
header {
  z-index: 100;
  box-shadow: 0 4px 4px rgb(0 0 0 / 4%);
  @apply md:sticky md:top-0 bg-white;
  @media(max-width: 1024px){
    display: none;
  }
}
.layout-header-mobile{
  display: none;
  @media(max-width: 1024px){
    display: block;
  }
}

.footer {
  color: #b0b0b0;
  background-color: #333;
  @media(max-width: 1024px){
    display: none;
  }
}
.layout-footer-mobile{
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
}
main {
  background-color: #efefef;
}

.wrapper {
  @apply h-full flex flex-col;
  main {
    flex: 1 0 auto;
  }

  footer {
    flex-shrink: 0;
  }
  @media(max-width: 1024px){
    padding-top: 54px;
  }
}
</style>
