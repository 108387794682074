/**
 *
 * Plugin files are automatically loaded by the "loadPlugins" method
 * in the "main.js" file. Below, you can find an example
 *
 * @example
 *
 * import {app} from '@/main'
 * import plugin from './plugin'
 *
 * app.use(plugin)
 */

import { app } from "@/main";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMapMarkerAlt,
  faTv,
  faHeart,
  faPlusCircle,
  faSignInAlt,
  faPaperclip,
  faTimes,
  faMapMarkedAlt,
  faSearch,
  faArrowUp,
  faArrowDown,
  faEye,
  faClock,
  faPhoneAlt,
  faIdBadge,
  faStar,
  faPrint,
  faClipboardList,
  faEyeSlash,
  faShare,
  faThumbsUp,
  faThumbsDown,
  faChevronLeft,
  faChevronRight,
  faUser,
  faSignOutAlt,
  faChevronDown,
  faChevronUp,
  faFilter,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faVk,
  faInstagram,
  faWhatsapp,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faMapMarkerAlt,
  faTv,
  faHeart,
  faPlusCircle,
  faSignInAlt,
  faVk,
  faInstagram,
  faPaperclip,
  faTimes,
  faMapMarkedAlt,
  faSearch,
  faArrowUp,
  faArrowDown,
  faEye,
  faClock,
  faPhoneAlt,
  faIdBadge,
  faWhatsapp,
  faStar,
  faPrint,
  faClipboardList,
  faEyeSlash,
  faShare,
  faThumbsUp,
  faThumbsDown,
  faChevronLeft,
  faChevronRight,
  faUser,
  faSignOutAlt,
  faChevronDown,
  faChevronUp,
  faFilter,
  faEnvelope,
  faTelegram
);
app.config.productionTip = false;
app.component("font-awesome-icon", FontAwesomeIcon);
