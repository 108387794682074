<template>
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8136 16.8863L12.814 16.8865C13.3167 17.1644 13.9471 17.0336 14.2977 16.5794C14.2979 16.5792 14.298 16.5789 14.2982 16.5787L16.0743 14.3064C17.019 14.9467 17.9664 15.5887 18.9137 16.2307C19.8554 16.8688 20.7971 17.507 21.7363 18.1434L20.2143 20.6169L20.2142 20.6169L20.2121 20.6206C19.9392 21.0839 19.4981 21.4246 18.9803 21.5721C17.1881 22.0628 13.092 22.2152 6.43824 15.5671C-0.215526 8.91905 -0.0633298 4.82619 0.427755 3.03575C0.575335 2.51849 0.916344 2.07776 1.37969 1.80512L1.37972 1.80516L1.3837 1.8027C1.6249 1.6538 1.86607 1.50493 2.10722 1.35608C2.67789 1.00382 3.24845 0.651628 3.819 0.299283C4.30191 1.01143 4.78478 1.72366 5.26768 2.4359C6.06371 3.61002 6.85979 4.7842 7.65612 5.95821L5.4249 7.71419C4.96994 8.06468 4.83972 8.69468 5.11782 9.19685L5.11805 9.19726C5.16133 9.27502 5.20475 9.35466 5.24954 9.43684C5.4592 9.82143 5.69906 10.2614 6.09608 10.8228C6.57812 11.5044 7.29013 12.3634 8.46671 13.5403C9.64337 14.7173 10.5032 15.4288 11.1858 15.9101C11.7451 16.3045 12.1845 16.5434 12.5681 16.7519C12.6524 16.7977 12.734 16.842 12.8136 16.8863Z" fill="white" stroke="white" stroke-width="0.442321"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "phone"
})
</script>

<style scoped lang="scss">

</style>
