<template>
  <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.8251 0H0.846191L11.3357 8.60185L21.9437 0.0240993C21.9049 0.0128028 21.8652 0.00475396 21.8251 0Z" fill="white"/>
    <path d="M11.845 10.1969C11.5477 10.4392 11.12 10.4392 10.8227 10.1969L0.0395508 1.35242V15.1967C0.0395508 15.6403 0.400803 16 0.846447 16H21.8253C22.271 16 22.6322 15.6403 22.6322 15.1967V1.47131L11.845 10.1969Z" fill="white"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "mail"
})
</script>

<style scoped lang="scss">

</style>
