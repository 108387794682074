<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    class="mr-2"
  >
    <path
      d="M10.5946 14.4995L10.1479 20.6418C10.787 20.6418 11.0637 20.3734 11.3956 20.0511L14.3915 17.2517L20.5994 21.6967C21.738 22.3171 22.5401 21.9904 22.8472 20.6726L26.9221 2.00354L26.9232 2.00244C27.2844 0.356844 26.3146 -0.286652 25.2053 0.117045L1.25348 9.0831C-0.381184 9.7035 -0.356434 10.5945 0.975599 10.9982L7.09912 12.8605L21.3228 4.15842C21.9922 3.72503 22.6009 3.96483 22.1002 4.39822L10.5946 14.4995Z"
      fill="#333333"
    />
  </svg>
</template>

<script>
export default {
  name: "telegram",
};
</script>
