export default {
  getLocality: (state) => {
    return state.locality;
  },
  getLocalities(state) {
    return (text_search = "") => {
      return Object.entries(state.localities).filter((item) => {
        return item[0].toLowerCase().includes(text_search.toLowerCase());
      });
    };
  },
};
