import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  checkingAuth: true,
  isAuth: false,
  User: {}
};

export default {
  state,
  getters,
  actions,
  mutations,
};
