<template>
  <button :class="$style.ReturnToTop" @click="scrollToTop">
    <svg
      width="100%"
      height="50%"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.45681 1.37295e-06L6.32724e-07 4.23853L2.05576 6.19364L4.03108 4.31512L4.03108 11L6.93839 11L6.93839 4.31512L8.94424 6.22277L11 4.26765L6.51257 1.54085e-06L4.45681 1.37295e-06Z"
        fill="#ffffff"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "ReturnToTop",
  methods: {
    scrollToTop() {
      document.querySelector("#app").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped module lang="scss">
.ReturnToTop {
  @apply -mt-14 bottom-8 right-8 rounded-full bg-blue text-white w-10 h-10 text-white float-right focus:outline-none hover:bg-darkblue hidden md:sticky md:inline;
}
</style>
