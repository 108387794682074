
import { defineComponent } from "vue";
export default defineComponent({
  name: "CodeInput",
  emits: ["update:modelValue"],
  props: {
    SymbolsCount: {
      type: Number,
      default: (): number => {
        return 6;
      },
    },
    modelValue: {
      type: String,
      default: (): string => {
        return "";
      },
    },
    error: {
      type: String,
      default: ():string =>{
        return "";
      }
    }
  },
  data(): {
    code: { [key:string]: string };
    focus: number;
  } {
    return {
      code: {},
      focus: 0,
    };
  },
  mounted() {
    this.SetDefault();
  },
  watch: {
    codeString() {
      if (this.codeString.length === 6) {
        this.$emit("update:modelValue", this.codeString);
      } else {
        this.$emit("update:modelValue", "");
      }
    },
  },
  computed: {
    codeString(): string {
      let res = "";
      for (let key in this.code) {
        res += String(this.code[key]);
      }
      return res;
    },
  },
  methods: {
    SetDefault(): void {
      for (let x = 1; x <= this.SymbolsCount; x++) {
        this.code[x] = "";
      }
    },
    OnSetSymbol(event: InputEvent): void {
      const data = event.data !== null ? event.data : "";
      this.code[this.focus] = data;
      if (data) {
        this.focus++;
        this.FocusElement(this.focus);
      }
    },
    OnDeleteSymbol(): void {
      this.code[this.focus] = "";
      this.focus--;
      this.FocusElement(this.focus);
    },
    SetFocus(num: number): void {
      this.focus = num;
    },
    FocusElement(elemId: number): void {
      this.SetFocus(elemId);
      const inputToFocus = document.querySelector<HTMLElement>(`#code-input-${elemId}`);
      inputToFocus?.focus();
    },
    OnPaste(focusedInputIndex: number): void {
      const focusedInput = document.querySelector<HTMLInputElement>(`#code-input-${focusedInputIndex}`);
      setTimeout(() => {
        const symbols: string[] = focusedInput?.value.trim().split("") ?? [];
        this.SetDefault();
        for (let key in this.code) {
          this.code[key] = symbols[Number(key) - 1];
        }
      }, 200);
    },
  },
});
