<template>
  <div :class="$style.cookieWrapper">
    <div :class="$style.cookieContainer">
      <div :class="$style.cookieInner">
        <div :class="$style.cookieText">
          Сайт loft.ug использует cookie, чтобы сделать пользование сайтом
          проще.
          <router-link :class="$style.link" to="about_cookies"
            >Узнать подробнее
          </router-link>
        </div>
        <button @click="agree" :class="$style.cookieBtn">
          ОК
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieNotice",
  beforeMount() {
    if (localStorage.getItem("cookie_agree")) {
      this.$emit("close");
    }
  },
  methods: {
    agree: function() {
      localStorage.setItem("cookie_agree", true);
      this.$emit("close");
    },
  },
};
</script>

<style module scoped lang="scss">
.cookieWrapper {
  @apply py-5 fixed w-full bottom-0 shadow-2xl px-3 z-20 bg-blue;
  .cookieContainer {
    @apply container mx-auto;
    .cookieInner {
      @apply flex flex-wrap md:flex-nowrap items-center text-white font-semibold;
      .link {
        @apply underline;
      }

      .cookieBtn {
        @apply w-full md:w-auto ml-auto bg-black py-2 px-7 rounded text-white font-semibold;
      }

      .cookieText {
        @apply w-full md:w-auto mb-5 md:mb-0;
      }
    }
  }
}
</style>
