<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.1233 0H4.14016C1.85479 0 0 1.85479 0 4.14016V24.1233C0 26.4087 1.85479 28.2635 4.14016 28.2635H24.1233C26.4087 28.2635 28.2635 26.4087 28.2635 24.1233V4.14016C28.2635 1.85479 26.4087 0 24.1233 0ZM14.1317 21.6392C10.0248 21.6392 6.67945 18.2939 6.67945 14.1869C6.67945 10.0798 10.0248 6.73465 14.1317 6.73465C18.2387 6.73465 21.584 10.0798 21.584 14.1869C21.584 18.2939 18.2387 21.6392 14.1317 21.6392ZM23.2401 6.73465C22.3292 6.73465 21.584 5.98942 21.584 5.07859C21.584 4.16776 22.3292 3.42253 23.2401 3.42253C24.1509 3.42253 24.8961 4.16776 24.8961 5.07859C24.8961 5.98942 24.1509 6.73465 23.2401 6.73465Z" fill="white"/>
    <circle cx="13.814" cy="14.4497" r="7.1916" stroke="white" stroke-width="1.43832"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "instagramWhite"
})
</script>

<style scoped lang="scss">

</style>
