<template>
  <div class="code-modal">
    <div class="code-modal__close" @click="$emit('close')">
      <modal-cross/>
    </div>
    <div class="code-modal__title">Введите код</div>
    <div class="code-modal__subtitle">Отправлен на {{ login }}</div>
    <div class="code-modal__change-mail" @click="ChangeLogin">Изменить e-mail</div>
    <div class="code-modal__input">
      <code-input v-model="confirmation_code" :error="error"/>
    </div>
    <div class="code-modal__button">
      <base-button :disabled="disabled" size="auth" @click="SendConfirmationCode">Продолжить</base-button>
    </div>
    <div class="code-modal__send-again">
      <p v-if="timeout > 0">Повторный запрос можно отправить через {{ timeout }} сек.</p>
      <p v-else class="code-modal__send-again__update" @click="UpdateCode">Отправить код еще раз</p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import CodeInput from "@/components/base/input/CodeInput.vue";
export default defineComponent({
  name: "Code",
  props: {
    login: {
      type: String,
      required: true
    },
    error: {
      type: String,
      default: (): string => {
        return "";
      }
    }
  },

  components: {
    CodeInput
  },

  emits: ['code', 'changeComponent', 'updateCode'],
  data() {
    return {
      confirmation_code: "",
      timeout: 60
    }
  },
  mounted() {
    this.StartTimer();
  },
  computed: {
    disabled(): boolean {
      return this.confirmation_code.length < 6;
    }
  },
  methods: {
    SendConfirmationCode(): void {
      this.$emit('code', this.confirmation_code);
    },
    UpdateCode(): void {
      this.timeout = 60;
      this.StartTimer();
      this.$emit('updateCode');
    },
    ChangeLogin(): void {
      this.$emit('changeComponent', "login");
    },
    StartTimer(): void {
      let tmp = setInterval(() => {
        this.timeout--;
        if (this.timeout === 0) clearInterval(tmp);
      }, 1000)
    }
  }
})
</script>

<style lang="scss" scoped>
.code-modal {
  width: 308px;
  background: #FFF;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  position: fixed;
  transform: translate(-50%, 0);
  top: 30%;
  left: 50%;
  padding: 30px;

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  &__title {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 100%;
    margin-bottom: 10px;
    @apply text-black;
  }

  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100.9%;
    margin-bottom: 15px;
    @apply text-black;
  }

  &__change-mail {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100.9%;
    text-decoration-line: underline;
    margin-bottom: 25px;
    cursor: pointer;
    @apply text-blue;
  }

  &__input {
    margin-bottom: 10px;
  }

  &__button {
    margin-bottom: 10px;
  }

  &__send-again {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 115%;
    color: #B4B4B4;

    &__update {
      cursor: pointer;
      @apply text-blue;
    }
  }
}
</style>
