<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    class="mr-2"
  >
    <path
      d="M11.5029 0H11.4971C5.15631 0 0 5.15775 0 11.5C0 14.0156 0.81075 16.3472 2.18931 18.2404L0.756125 22.5127L5.17644 21.0996C6.99487 22.3043 9.16406 23 11.5029 23C17.8437 23 23 17.8408 23 11.5C23 5.15919 17.8437 0 11.5029 0Z"
      fill="#333333"
    />
    <path
      class="svg-phone"
      d="M18.1943 16.2394C17.9169 17.0228 16.8157 17.6726 15.9374 17.8623C15.3366 17.9903 14.5517 18.0923 11.9096 16.997C8.52999 15.5968 6.35361 12.1626 6.18399 11.9398C6.02155 11.717 4.81836 10.1214 4.81836 8.47114C4.81836 6.82089 5.65642 6.01733 5.99424 5.67233C6.27167 5.38914 6.73024 5.25977 7.17011 5.25977C7.31242 5.25977 7.44036 5.26695 7.55536 5.2727C7.89317 5.28708 8.0628 5.3072 8.28561 5.84052C8.56305 6.50895 9.23867 8.1592 9.31917 8.32883C9.40111 8.49845 9.48305 8.72845 9.36805 8.95127C9.26024 9.18127 9.16536 9.28333 8.99574 9.47883C8.82611 9.67433 8.66511 9.82383 8.49549 10.0337C8.34024 10.2163 8.16486 10.4118 8.36036 10.7496C8.55586 11.0802 9.23149 12.1828 10.2262 13.0683C11.5099 14.2111 12.5507 14.5762 12.923 14.7315C13.2004 14.8465 13.5311 14.8191 13.7337 14.6035C13.9911 14.3261 14.3087 13.8661 14.6322 13.4133C14.8622 13.0884 15.1526 13.0481 15.4573 13.1631C15.7678 13.271 17.4109 14.0831 17.7487 14.2513C18.0865 14.421 18.3093 14.5015 18.3912 14.6438C18.4717 14.7861 18.4717 15.4545 18.1943 16.2394Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "whatsApp",
};
</script>
