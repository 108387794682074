<template>
  <Layout>
    <router-view v-slot="{ Component }">
      <FadeTransition>
        <component :is="Component" />
      </FadeTransition>
    </router-view>
  </Layout>
</template>

<script>
import Layout from "@/layout/Layout";
import FadeTransition from "@/components/transitions/FadeTransition";
export default {
  components: {
    Layout,
    FadeTransition,
  },
  created() {
    this.$store.dispatch('user/RefreshToken');
    this.$store.dispatch('site/GetSitemap');
    this.$store.dispatch("search/get_filter_data");
  },
};
</script>
