export default function ClearEmptyFields(
  _obj: any,
  deep: Boolean = false
): any {
  let obj: any = {};
  Object.assign(obj, { ..._obj });
  Object.keys(obj).map((key) => {
    if (JSON.stringify(obj[key]) === "{}") {
      delete obj[key];
    } else {
      Object.keys(obj[key]).forEach((key2) => {
        if (
          JSON.stringify(obj[key][key2]) === "{}" ||
          JSON.stringify(obj[key][key2]) === "[]" ||
          obj[key][key2] === "" ||
          obj[key][key2] === null
        ) {
          delete obj[key][key2];
        } else {
          if (obj[key][key2] && obj[key][key2].from === "") {
            delete obj[key][key2].from;
          }
          if (obj[key][key2] && obj[key][key2].to === "") {
            delete obj[key][key2].to;
          }
          if (JSON.stringify(obj[key][key2]) === "{}") {
            delete obj[key][key2];
          }
        }
      });
    }
    if (!deep) {
      for (let k in obj[key]) {
        if (obj[key][k] === "") {
          delete obj[key][k];
        }
      }
      if (
        JSON.stringify(obj[key]) === "{}" ||
        JSON.stringify(obj[key]) === "[]"
      )
        delete obj[key];
    }
  });
  return obj;
}
