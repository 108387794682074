
import {defineComponent} from 'vue';

export default defineComponent({
  name: "Login",
  emits: ["login","close"],
  data(): {
    identity_name: string;
    identity_value: string;
  }{
    return {
      identity_name: "phone",
      identity_value: ""
    }
  },
  computed: {
    disabled():boolean{
      return this.identity_value.length < 11
    }
  },
  methods: {
    SendLoginData(){
      this.$emit("login",this.identity_name,this.identity_value);
    }
  }
})
