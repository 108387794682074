<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M13 11.0234C12.012 11.0234 11.1413 11.5076 10.5944 12.2438L5.86633 9.83224C5.94483 9.56572 6 9.2895 6 8.99805C6 8.60273 5.91863 8.2269 5.77807 7.88123L10.7262 4.91526C11.2769 5.559 12.0863 5.97656 13 5.97656C14.6543 5.97656 16 4.63612 16 2.98828C16 1.34044 14.6543 0 13 0C11.3457 0 10 1.34044 10 2.98828C10 3.36803 10.0785 3.72825 10.2087 4.06267L5.24583 7.03734C4.69563 6.41272 3.8979 6.00977 3 6.00977C1.3457 6.00977 0 7.35021 0 8.99805C0 10.6459 1.3457 11.9863 3 11.9863C4.00433 11.9863 4.8897 11.488 5.4345 10.7311L10.147 13.1348C10.0602 13.4139 10 13.7046 10 14.0117C10 15.6596 11.3457 17 13 17C14.6543 17 16 15.6596 16 14.0117C16 12.3639 14.6543 11.0234 13 11.0234Z"
      fill="#333333"
    />
  </svg>
</template>

<script>
export default {
  name: "share",
};
</script>

<style scoped></style>
