import TYPES from "../../../constants/FilterTypes";

export function offer_property_types_rules(types, new_type, list) {
  function get_number(name) {
    let _name = list.find((type) => type.name.toUpperCase() === name.toUpperCase());
    return _name?.value;
  }

  function set_rules() {
    let rules = new Map();
    if (get_number(TYPES.segment)) rules.set(get_number(TYPES.segment), new Set([]));
    if (get_number(TYPES.commerce))
      rules.set(get_number(TYPES.commerce), new Set([]));
    if (get_number(TYPES.flat))
      rules.set(
        get_number(TYPES.flat),
        new Set([get_number(TYPES.new_flats), get_number(TYPES.house)])
      );
    if (get_number(TYPES.new_flats))
      rules.set(
        get_number(TYPES.new_flats),
        new Set([get_number(TYPES.flat), get_number(TYPES.house)])
      );
    if (get_number(TYPES.house))
      rules.set(
        get_number(TYPES.house),
        new Set([get_number(TYPES.flat), get_number(TYPES.new_flats)])
      );
    return rules;
  }

  let rules = set_rules();
  let allowed_types = [];
  types.forEach((item) => {
    if (rules.get(new_type) && rules.get(new_type).has(item)) {
      allowed_types.push(item);
    }
  });
  allowed_types.push(new_type);

  return allowed_types;
}
