<template>
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5131 1.48438L14.5131 1.48437C13.8729 0.846204 12.9681 0.5 11.7321 0.5C11.423 0.5 11.1007 0.554581 10.7636 0.669235C10.4204 0.786021 10.1029 0.942905 9.81016 1.13963L9.80985 1.13984C9.48908 1.35503 9.21815 1.55397 8.99464 1.73632L14.5131 1.48438ZM14.5131 1.48438C15.1525 2.12168 15.5 3.02628 15.5 4.26559C15.5 5.4278 14.9119 6.67523 13.6015 8.01389L8.04233 13.4795L8.04227 13.4794L8.03584 13.486C8.03021 13.4917 8.02635 13.4946 8.02448 13.4959C8.02269 13.4971 8.02185 13.4975 8.02164 13.4976C8.02143 13.4977 8.0203 13.4981 8.01755 13.4987C8.01471 13.4992 8.00909 13.5 7.99998 13.5C7.99088 13.5 7.98529 13.4992 7.9825 13.4987C7.9798 13.4982 7.97873 13.4977 7.97857 13.4976C7.97842 13.4976 7.97761 13.4972 7.97583 13.496C7.97398 13.4947 7.9701 13.4918 7.96442 13.486L7.96448 13.4859L7.95798 13.4795L2.38662 7.99248L2.36991 7.97603L2.35174 7.96121C2.31744 7.93324 2.25318 7.87284 2.15233 7.76705C2.06625 7.67642 1.91391 7.49447 1.68826 7.20651L1.68824 7.20649C1.47522 6.93467 1.28543 6.65669 1.11863 6.37289L1.11862 6.37287C0.962845 6.10783 0.817113 5.77526 0.685517 5.36921L0.6855 5.36916C0.559983 4.98201 0.5 4.61471 0.5 4.2656C0.5 3.02626 0.847521 2.12167 1.4869 1.48439L1.48691 1.48437M14.5131 1.48438L1.48691 1.48437M8.00009 2.6831L7.64294 2.31872C7.44155 2.11325 7.22901 1.91907 7.00531 1.7363L8.00009 2.6831ZM8.00009 2.6831L8.35717 2.31864M8.00009 2.6831L8.35717 2.31864M8.35717 2.31864C8.55836 2.11329 8.77089 1.91915 8.99459 1.73636L8.35717 2.31864ZM1.48691 1.48437C2.12704 0.846289 3.03194 0.5 4.26781 0.5C4.57684 0.5 4.899 0.554587 5.23612 0.669211L1.48691 1.48437ZM6.19 1.13974C6.51091 1.35519 6.78188 1.55396 7.00512 1.73615L5.23629 0.669267C5.57944 0.786106 5.89688 0.942882 6.18994 1.1397L6.19 1.13974Z" stroke="#333333"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: "heartTransparent"
})
</script>

<style scoped lang="scss">

</style>
