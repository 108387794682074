<template>
  <div class="authorization">
    <component
      :is="component"
      :error="error"
      :login="login"
      @authorize="AuthorizeUser"
      @changeComponent="ChangeComponent"
      @close="CloseAuthModal"
      @code="SendCode"
      @login="SendLogin"
      @updateCode="UpdateCode"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {authorize_request, confirm_code, login_request, send_code_again} from "@/api/auth";
import LoginType from "@/components/common/Auth/steps/LoginType.vue";
import Login from "@/components/common/Auth/steps/Login.vue";
import Code from "@/components/common/Auth/steps/Code.vue";
import Password from "@/components/common/Auth/steps/Password.vue";

export default defineComponent({
  name: "Authorization",
  components: {
    LoginType,
    Login,
    Code,
    Password
  },
  data() {
    return {
      component: "login",
      error: "",
      login: ""
    }
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  methods: {
    //Отправляем логин пользователя на проверку существования
    SendLogin(identity_name: string, identity_value: string): void {
      login_request(identity_name, identity_value)
        .then((response) => {
          this.login = identity_value;
          if (response.data.status === 1) {
            this.component = "password";
          } else {
            this.component = "code";
          }
        })
    },
    //Отправка кода подтверждения
    SendCode(confirmation_code: string): void {
      confirm_code("phone", this.login, confirmation_code)
        .then(({data}) => {
          localStorage.setItem('token', data.access_token);
          localStorage.setItem('refresh_token', data.refresh_token);
          this.$store.commit('user/AuthorizeUser');
          this.CloseAuthModal();
        })
        .catch(({response}) => {
          this.error = response.data.message;
        })
    },
    UpdateCode(): void {
      send_code_again();
    },
    //Авторизуем пользователя
    AuthorizeUser(password: string): void {
      authorize_request(this.login, password)
        .then((response) => {
          const User = {
            id: response.data.user_id,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
          }
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('refresh_token', response.data.refresh_token);
          this.$store.commit('user/AuthorizeUser', User);
          this.CloseAuthModal();
        })
        .catch(({response}) => {
          this.error = response.data.message;
        })
    },
    //Поменять модальное окно
    ChangeComponent(component: string): void {
      this.component = component;
      this.error = "";
      this.login = "";
    },
    //Закрыть окно авторизации
    CloseAuthModal(): void {
      this.component = "login";
      this.login = "";
      this.error = "";
      this.$store.commit("site/HIDE_AUTH");
    }
  }
})
</script>

<style lang="scss" scoped>
.authorization {
  z-index: 1000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.6);
  backdrop-filter: blur(2px);
}
</style>
