<template>
  <svg
    width="38"
    height="30"
    viewBox="0 0 38 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.471 0.986977H27.6197C27.6197 0.441996 27.0651 0 26.3805 0H4.82877C4.14449 0 3.58966 0.441868 3.58966 0.986977V27.2182C3.58966 27.7633 4.14449 28.2052 4.82877 28.2052C28.3216 28.2052 26.4905 28.2627 26.8952 28.1152C27.2087 28.0008 27.4517 27.7856 27.5591 27.5217H29.4709C29.471 27.3322 29.471 1.35687 29.471 0.986977Z"
      fill="#33B6E3"
    />
    <path
      d="M28.9564 0H26.3806C27.0649 0 27.6197 0.441868 27.6197 0.986977V27.2182C27.6197 27.7633 27.0649 28.2052 26.3806 28.2052H28.9564C29.6408 28.2052 30.1955 27.7633 30.1955 27.2182V0.986977C30.1955 0.441868 29.6408 0 28.9564 0V0Z"
      fill="#33B6E3"
    />
    <path
      d="M32.2253 7.23031L29.3211 5.82088L26.8138 2.53384H9.58309C8.63717 2.53384 7.8703 3.12991 7.8703 3.86513V27.6687C7.8703 28.4039 8.63717 29 9.58309 29H32.192C32.2033 29 32.2142 28.9989 32.2253 28.9987V7.23031Z"
      fill="white"
    />
    <path
      d="M33.9049 7.8628L31.3291 6.6127V27.4861C31.3291 28.2214 30.5622 28.8174 29.6163 28.8174H32.1921C33.1381 28.8174 33.9049 28.2214 33.9049 27.4861V7.8628H33.9049Z"
      fill="#FCFCFC"
    />
    <path
      d="M28.5266 8.04543H33.9048L26.8138 2.53384V6.71414C26.8138 7.44936 27.5807 8.04543 28.5266 8.04543Z"
      fill="#33B6E3"
    />
    <path
      d="M29.7942 10.8797H11.9811C11.6388 10.8797 11.3615 10.664 11.3615 10.3981C11.3615 10.1322 11.6388 9.91653 11.9811 9.91653H29.7942C30.1364 9.91653 30.4138 10.1322 30.4138 10.3981C30.4138 10.664 30.1364 10.8797 29.7942 10.8797Z"
      fill="#33B6E3"
    />
    <path
      d="M29.7942 14.0658H11.9811C11.6389 14.0658 11.3615 13.8502 11.3615 13.5843C11.3615 13.3183 11.6389 13.1027 11.9811 13.1027H29.7942C30.1364 13.1027 30.4138 13.3183 30.4138 13.5843C30.4138 13.8502 30.1364 14.0658 29.7942 14.0658Z"
      fill="#33B6E3"
    />
    <path
      d="M36.5456 8.50771H35.2028C34.9153 8.01064 34.3779 7.67732 33.772 7.67732C32.9221 7.67732 14.1681 7.67732 12.9724 7.67732L10.7975 4.07878H9.4791C9.24751 3.76917 8.88165 3.58163 8.48736 3.58163H1.23911C0.554748 3.58163 0 4.13646 0 4.82074V27.3631C0 28.2756 0.739717 29.0153 1.65215 29.0153H33.772C34.0448 29.0153 34.3223 28.9446 34.5676 28.809H34.5708C34.779 28.6937 34.9591 28.5343 35.1 28.3441H36.5455V8.50771H36.5456Z"
      fill="#333333"
    />
    <path
      d="M9.58534 4.24629L11.3806 7.67732H13.9565L12.1612 4.24629C11.9474 3.83766 11.5244 3.58163 11.0633 3.58163H8.48746C8.94856 3.58163 9.37153 3.83766 9.58534 4.24629Z"
      fill="#333333"
    />
    <path
      d="M36.3479 7.67746H33.7721C34.6846 7.67746 35.4243 8.41718 35.4243 9.32961V27.3633C35.4243 28.2758 34.6846 29.0154 33.7721 29.0154H36.3479C37.2604 29.0154 38.0001 28.2757 38.0001 27.3633V9.32969C38.0001 8.41718 37.2603 7.67746 36.3479 7.67746Z"
      fill="#333333"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "folder",
});
</script>

<style scoped lang="scss"></style>
