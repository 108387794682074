import {
  get_additional_filter,
  get_filters_data_list,
  get_offers,
} from "../../../api/searchApi";
import {CopyObject} from "@/helpers/CopyObject";
import ClearEmptyFields from "../../../ts-helpers/ClearEmptyFields";

export default {
  //Стандатрный метод поиска
  Search({ commit, state }, payload) {
    const SaveSearch = payload?.save ?? true;
    let add_filter_copy = CopyObject(state.additional_filter);
    let main_filter_copy = CopyObject(state.main_filter);
    let add_filter = ClearEmptyFields(add_filter_copy, true);
    let main_filter = ClearEmptyFields(main_filter_copy);
    const params = {
      page: 1,
      per_page: state.per_page(),
      sort_by: state.sort_by,
      sort_ord: state.sort_ord,
      main_filter: main_filter,
      additional_filter: add_filter,
    };
    commit("OBJECTS_LOADING_START");
    get_offers(params)
      .then((response) => {
        commit("OBJECTS_LOADING_SUCCESS", response.data);
        commit("SAVE_SEARCH", { params: { ...params }, save: SaveSearch });
      })
      .catch(({ response }) => {
        commit("OBJECTS_LOADING_FAIL", response.status);
      });
  },
  //Метод поиска для пагинации
  SearchFromPagination({ commit, state }, payload) {
    state.old_search.page = payload;
    commit("OBJECTS_LOADING_START");
    let params = JSON.parse(localStorage.getItem("saved_search"));
    get_offers(state.old_search)
      .then((response) => {
        commit("OBJECTS_LOADING_SUCCESS", response.data);
        commit("SAVE_SEARCH", {
          params: {
            ...state.old_search
          },
          save: true,
        });
      })
      .catch(({response}) => {
        commit("OBJECTS_LOADING_FAIL", response.status);
      });
  },
  //Метод поиска для смены сортировки
  SearchAfterChangeSortType({ commit, state }) {
    state.old_search = {
      ...state.old_search,
      sort_by: state.sort_by,
      sort_ord: state.sort_ord,
      page: 1
    };
    commit("OBJECTS_LOADING_START");
    get_offers(state.old_search)
      .then((response) => {
        commit("OBJECTS_LOADING_SUCCESS", response.data);
      })
      .catch(({ response }) => {
        commit("OBJECTS_LOADING_FAIL", response.status);
      });
  },
  //Искать по старому Поиску, если его нет то по пустому
  SearchEmptyOrOld({ commit, state }) {
    commit("OBJECTS_LOADING_START");
    get_offers(state.old_search)
      .then((response) => {
        commit("OBJECTS_LOADING_SUCCESS", response.data);
      })
      .catch(({ response }) => {
        commit("OBJECTS_LOADING_FAIL", response.status);
      });
  },
  //Получение данных для фильтра
  get_filter_data({ commit }) {
    commit("SET_FILTER_LOADING_STATUS", { loading: true, error: false });
    //получить основной фильто
    get_filters_data_list()
      .then(({ data }) => {
        commit("SET_MAIN_FILTER", data);
        //получить дополнительные фильтры
        get_additional_filter()
          .then((res) => {
            commit("SET_ADDITIONAL_FILTER", res.data);
            commit("SET_FILTER_LOADING_STATUS", {
              loading: false,
              error: false,
            });
          })
          .catch(() => {
            commit("SET_FILTER_LOADING_STATUS", {
              loading: false,
              error: true,
            });
          });
      })
      .catch(() => {
        commit("SET_FILTER_LOADING_STATUS", { loading: false, error: true });
      });
  },
  //Очистить фильтр и выполнить поиск
  ClearFilterAndSearch({ commit, state }) {
    commit("RESET_FILTER");
    commit("OBJECTS_LOADING_START");
    localStorage.removeItem("saved_search");
    state.old_search = {
      per_page: state.per_page(),
      page: 1,
    };

    get_offers(state.old_search)
      .then((response) => {
        commit("OBJECTS_LOADING_SUCCESS", response.data);
      })
      .catch(({ response }) => {
        commit("OBJECTS_LOADING_FAIL", response.status);
      });
  },
  //Метод для поиска после перезагрузки
  SearchFromSavedSearchData({ commit, state }, payload) {
    let params = JSON.parse(localStorage.getItem("saved_search"));
    commit("OBJECTS_LOADING_START");

    get_offers({ ...params.params, per_page: state.per_page() })
      .then(async (response) => {
        state.main_filter = {
          ...state.main_filter,
          ...params.params.main_filter,
        };
        state.additional_filter = {
          ...state.additional_filter,
          ...params.params.additional_filter,
        };
        state.additional_filter_tab = state.main_filter.types[0] !== "5" ? state.main_filter.types[0] : state.main_filter.commerce_types[0];
        await commit("OBJECTS_LOADING_SUCCESS", response.data);
        await commit("SAVE_SEARCH", {
          params: { ...params.params, per_page: state.per_page() },
          save: true,
        });
        if (payload) {
          window.scrollTo({
            top: payload.old_pos,
            behavior: "smooth",
          });
          localStorage.removeItem('saved_position');
        }
      })
      .catch(({ response }) => {
        commit("OBJECTS_LOADING_FAIL", response.status);
      });
  },
};
