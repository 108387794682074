
import {defineComponent} from 'vue';

export default defineComponent({
  name: "BaseInput",
  emits: ['update:modelValue'],
  props: {
    placeholder: {
      type: String,
      default: (): string => {
        return "";
      }
    },
    autocomplete: {
      type: String,
      default: (): string => {
        return "on";
      }
    },
    modelValue: {
      type: String,
      default: (): string => {
        return "";
      }
    },
    error: {
      type: String,
      default: ():string =>{
        return "";
      }
    }
  },
  methods: {
    Update(e: InputEvent): void{
      const value = (e.target as HTMLInputElement).value;
      this.$emit('update:modelValue',value);
    }
  }
})
